<template>
  <div>
    <el-button
      type="primary"
      icon="el-icon-tickets"
      size="mini"
      @click="submit('edit')"
    >
      {{ $t('common.upload') }}
    </el-button>
  </div>
</template>

<script>
  import ncformCommon from '@ncform/ncform-common'

  export default {
    mixins: [ncformCommon.mixins.vue.controlMixin],

    data () {
      return {
        caller: 'choices',
      }
    },

    methods: {
      // you can handle the modelVal before $emit it (before this.$emit('input'))
      _processModelVal (modelVal) {
        return modelVal
      },
      submit (prop) {
        // console.log(prop)
        this.$store.commit('odkform/dialogUplChoice', { caller: this.caller, value: true })
      },

    },
  }
</script>

<style scoped>
.my-custom-comp {
  text-align: left;
  line-height: 3;
}
</style>
