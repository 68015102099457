/**
 * Functions used while form design
 */

// additional js files
// import findPath from './findPath'
import Vue from 'vue'
import findPath from './findPath'
import addToPath from './addToPath'
import QuestionTypes from '../questiontypes'

export default {
  data: () => ({
    QuestionTypes,

    activeTranslation: '',

    // List of fields in ODK Form to be translated
    transFields: [
      { name: 'odk_label', label: 'Label' },
      { name: 'odk_clabel', label: 'Label' },
      { name: 'odk_hint', label: 'Hint' },
      { name: 'odk_required_message', label: 'Required message' },
      { name: 'odk_constraint_message', label: 'Constraint message' },
    ],
  }),

  methods: {
    // Remove ModelValue by array of internalKey
    removeMValueByKeys (keys) {
      // console.log(JSON.parse(JSON.stringify(this.myValues)))
      // https://stackoverflow.com/a/9425230
      for (var i = keys.length - 1; i >= 0; i--) {
        // find index of the model
        const index = this.myValues.findIndex(x => x.internalKey === keys[i])
        // console.log(index)
        this.myValues.splice(index, 1)
      }
      // console.log(JSON.parse(JSON.stringify(this.myValues)))
    },

    // Delete provided keys from array of object
    cleanObj (item, keys) {
      function removeKeys (obj, keys) {
        if (Array.isArray(obj)) return obj.map(item => removeKeys(item, keys))

        if (typeof obj === 'object' && obj !== null) {
          return Object.keys(obj).reduce((previousValue, key) => {
            return keys.includes(key) ? previousValue : { ...previousValue, [key]: removeKeys(obj[key], keys) }
          }, {})
        }

        return obj
      }
      return (removeKeys(item, keys))
    },

    // Sort myValues based on treeData we need it for accessing only previous questions for coding
    sortModel () {
      // Get all internalKey values from all questions, including in groups https://stackoverflow.com/a/52363221
      const keys = []
      JSON.stringify(this.treeData, (key, value) => {
        if (key === 'internalKey') keys.push(value)
        return value
      })

      // Sort an array of objects (myValues) based on array of keys https://stackoverflow.com/a/35538704
      const itemPositions = {}
      for (const [index, id] of keys.entries()) {
        itemPositions[id] = index
      }
      this.myValues.sort((a, b) => itemPositions[a.internalKey] - itemPositions[b.internalKey])
    },

    // Get values of the selected group for saving template
    groupValues (group) {
      // console.log(group)
      // Get all internalKey values of group questions https://stackoverflow.com/a/52363221
      const keys = []
      JSON.stringify(group, (key, value) => {
        if (key === 'internalKey') keys.push(value)
        return value
      })
      // console.log(keys)
      const gValues = this.myValues.filter(
        v => keys.includes(v.internalKey),
      )
      return (gValues)
    },

    // change internal Key and odk_name of object
    newInternalKey (obj) {
      // console.log(obj)
      const max = 99
      const n = Math.floor(Math.random() * (max - 1) + 1)
      // update values' internalKeys
      obj.v.forEach(v => {
          const key = v.internalKey + n
          v.internalKey = key
          const nodkName = v.odk_name.slice(0, -key.toString().length) + key.toString()
          v.odk_name = nodkName
      })
      // traverse function to update questions' (nested) internalKey
      function runTraverse (target) {
        const key = target.internalKey + n
        target.internalKey = key
        const nodkName = target.odk_name.slice(0, -key.toString().length) + key.toString()
        target.odk_name = nodkName
        // console.log(target)
        if ('children' in target) {
          target.children.forEach(function (arrayItem) {
            // console.log(arrayItem)
            runTraverse(arrayItem)
          })
        }
      }
      // run traverse function
      runTraverse(obj.q)

      return (obj)
    },

    /* === Function to update treeData === */
    // Update choice
    async addChoiceToTree (prop) {
      // console.log('updateChoiceList')
      await Vue.nextTick()
      await Vue.nextTick()
      const myVal = this.$ncformGetValue('myForm', { ignoreHiddenField: false })
      const myValString = JSON.parse(JSON.stringify(myVal))
      // console.log(myValString.sectionChoices.choices)
      this.selectedItem.settings.choiceSource = myValString.sectionChoices.choice_source
      this.selectedItem.settings.choiceList = myValString.sectionChoices.choices
      this.selectedItem.settings.choiceFile = myValString.sectionChoices.external_file
      // console.log(this.selectedItem)
    },
    // Update choice
    async addLabelToTree (prop) {
      // console.log('updateChoiceList')
      await Vue.nextTick()
      await Vue.nextTick()
      const myVal = this.$ncformGetValue('myForm', { ignoreHiddenField: false })
      const myValString = JSON.parse(JSON.stringify(myVal))
      // console.log(myValString.sectionChoices.choices)
      this.selectedItem.settings.choiceSource = myValString.sectionChoices.choice_source
      this.selectedItem.settings.choiceList = myValString.sectionChoices.choices
      this.selectedItem.settings.choiceFile = myValString.sectionChoices.external_file
      // console.log(this.selectedItem)
    },

    /* === Translation (Language) related functions === */
    updateTranslations () {
      // Update qTypesUpdated for each language and transFields using mixins: findPath.js and addToPath.js

      const formLangs = this.languages
      const labs = []
      // add empty string ('') into array for each language
      for (let h = 0; h < formLangs.length; h++) {
        labs.push('')
      }
      // console.log(labs)
      for (let i = 0; i < this.transFields.length; i++) {
        const activeField = this.transFields[i].name
        // (how to use .map is here:
        // https://medium.com/front-end-weekly/immutability-in-array-of-objects-using-map-method-dd61584c7188 )
        const updated = QuestionTypes.map(q => {
          const sch = q.schema
          // console.log(sch)
          // Add languages for all labels (except choice labels)
          sch.globalConfig.constants.lang = this.languages
          // Add languages for choice label
          sch.globalConfig.constants.choice[0].lang = this.languages
          // console.log(sch.properties)
          // console.log(activeField)
          // find all occurance of active field and get their path
          const pathProperties = findPath(sch.properties, activeField)
          // console.log(pathProperties)
          // Update number of empty strings in properties, so we could see
          // the right number of fields for translations
          if (pathProperties.length > 0) {
            for (let k = 0; k < pathProperties.length; k++) {
              // construct a new path for adding languages
              const path = pathProperties[k] + '.value'
              // update languages in given path
              addToPath(sch.properties, path, labs)
            }
          }
          const pathValue = findPath(sch.value, activeField)
          // console.log(pathValue)
          // Update also in value
          if (pathValue.length > 0) {
            for (let j = 0; j < pathValue.length; j++) {
              // update languages in given path
              addToPath(sch.value, pathValue[j], labs)
            }
          }
          return q
        })
        // Assign updated questionTypes in into qTypesUpdated (https://stackoverflow.com/a/23481096)
        this.qTypesUpdated = JSON.parse(JSON.stringify(updated))
        // console.log(updated)
      }
      // console.log(this.qTypesUpdated)
      this.questionUnselected()
    },
    addTranslation (l) {
      // generate language structure and push into languages
      const lang = { code: l.code, label: l.label, default: false, done: 0, full: `${l.label} (${l.code})` }
      this.languages.push(lang)
      // I need a pure object without '__dataSchema...' prototyps etc. to find only actual paths
      // const mVCopy = JSON.parse(JSON.stringify(this.myValues))
      // console.log(mVCopy)

      // Add new empty values to current myValues in all places where transFields exists
      for (let i = 0; i < this.transFields.length; i++) {
        const activeField = this.transFields[i].name
        // console.log(activeField)
        // (how to use .map is here:
        // https://medium.com/front-end-weekly/immutability-in-array-of-objects-using-map-method-dd61584c7188 )
        this.myValues.map(val => {
          // console.log(val)
          // find all occurance of active field and get their path
          const pathValue = findPath(val, activeField)
          // console.log(pathValue)
          if (pathValue.length > 0) {
            for (let j = 0; j < pathValue.length; j++) {
              // console.log(pathValue[j])
              // make an array from pathValue
              var a = pathValue[j].split('.')
              // console.log(a)
              // Get existing value from model's transFields
              let valueObj = JSON.parse(JSON.stringify(val))
              for (var k = 0, n = a.length; k < n; ++k) {
                const pathElement = a[k]
                if (pathElement in valueObj) {
                  valueObj = valueObj[pathElement]
                } else {
                  return
                }
              }
              // Add empty string into end of current value of transFields
              valueObj.push('')
              // console.log(valueObj)
              // Update transFields value with new valueObj
              addToPath(val, pathValue[j], valueObj)
            }
          }
          return val
        })
      }
      // putting back revized object back to models
      // this.myValues = mVCopy

      this.updateTranslations()
      this.dialogAdd = false
    },
    deleteTranslation () {
      // console.log(this.activeTranslation)

      // Find index of specific language using findIndex method.
      const langIndex = this.languages.findIndex(
        lang => lang.code === this.activeTranslation,
      )
      if (langIndex !== -1) this.languages.splice(langIndex, 1)
      // I need a pure object without '__ob__...' prototyps etc. to find only actual paths
      // const mVCopy = JSON.parse(JSON.stringify(this.myValues))
      // console.log(mVCopy)

      // Clear deleted language from mVCopy (clone of myValues) in all places where transFields exists
      for (let i = 0; i < this.transFields.length; i++) {
        const activeField = this.transFields[i].name
        // console.log(activeField)
        this.myValues.map(val => {
          // console.log(val)
          // find all occurance of active field and get their path
          const pathValue = findPath(val, activeField)
          // console.log(pathValue)
          if (pathValue.length > 0) {
            for (let j = 0; j < pathValue.length; j++) {
              // console.log(pathValue[j])
              // make an array from pathValue
              var a = pathValue[j].split('.')
              // console.log(a)
              // Get existing value from model's transFields
              let valueObj = JSON.parse(JSON.stringify(val))
              for (var k = 0, n = a.length; k < n; ++k) {
                const pathElement = a[k]
                if (pathElement in valueObj) {
                  valueObj = valueObj[pathElement]
                } else {
                  return
                }
              }
              // remove deleted translation's values from current values of transFields
              valueObj.splice(langIndex, 1)
              // console.log(valueObj)
              // Update transFields value with new valueObj
              addToPath(val, pathValue[j], valueObj)
            }
          }
          return val
        })
      }
      // putting back revized object back to models
      // this.myValues = mVCopy

      this.updateTranslations()
      this.dialogDelete = false
      // this.dialogLangs = false
    },
    defaultTranslation (lang) {
      // console.log(lang)
      const transField = this.transFields.map(el => { return el.name })

      // Traverse function to change all occurance of field from target
      function updateTraversal (target, field) {
        if (typeof target === 'object') {
          for (const key in target) {
            if (key === field) {
              const rfield = target[field].splice(index, 1)
              target[field].splice(0, 0, rfield[0])
            }
            updateTraversal(target[key], field)
          }
        }
        // return modified target
        return target
      }

      // Get the index of language object inside an array, matching a condition
      const index = this.languages.findIndex(x => x.code === lang)
      // Cut the the language (choosed to be default)
      const removed = this.languages.splice(index, 1)
      // Paste that language into the top [0]
      this.languages.splice(0, 0, removed[0])
      // Change all language into default = false
      this.languages.map(l => { l.default = false })
      // Change the top language as default
      this.languages[0].default = true
      // Do the same with all translation fields in each model
      for (let i = 0; i < this.myValues.length; i++) {
        for (let j = 0; j < transField.length; j++) {
          const field = transField[j]
          // traversal modify all occurence
          updateTraversal(this.myValues[i], field)
        }
      }

      // Update globalConfig of qTypesUpdated using .map function
      this.qTypesUpdated.map(q => {
        q.schema.globalConfig.constants.lang = this.languages
        q.schema.globalConfig.constants.choice[0].lang = this.languages
        return q
      })

      this.questionUnselected()
    },
    removeTranslationRequest (lang) {
      this.dialogConfirm = {
        active: true,
        type: 'removeTranslation',
        title: 'Heads up',
        text: 'All existing translations for this language will be deleted immediately. You cannot undo this action.',
        data: lang,
      }
      this.activeTranslation = lang
      // console.log(this.activeTranslation)
      this.dialogDelete = true
    },
  },
}
