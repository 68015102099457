module.exports = {
  title: 'Group',
  icon: 'mdi-group', // 'mdi-file-tree-outline', 'mdi-code-braces',
  class: 'odkgroup',
  // ==== SCHEMA =====
  schema: {
    type: 'object',
    properties: {
      internalKey: {
        type: 'number',
        ui: {
          hidden: true,
        },
      },
      type: {
        type: 'string',
        ui: {
          hidden: true,
        },
      },
      odk_name: {
        type: 'string',
        ui: {
          noLabelSpace: true,
          widgetConfig: {
            size: 'mini',
            clearable: true,
            updateOn: 'blur',
            compound: {
              prependLabel: 'Name',
            },
          },
        },
        rules: {
          required: {
            value: true,
            errMsg: 'Required!',
          },
          pattern: {
            value: '^[a-zA-Z0-9_]+$',
            errMsg: 'Only numbers, letters and underscore',
          },
        },
      },
      sectionLabel: {
        type: 'object',
        properties: {
          odk_label: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widgetConfig: {
                  size: 'mini',
                  clearable: true,
                  updateOn: 'blur',
                },
              },
              rules: {
                required: true,
              },
            },
            ui: {
              showLegend: false,
              noLabelSpace: true,
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
                disableCollapse: true,
              },
            },
          },
        },
        ui: {
          legend: 'Question label',
          noLabelSpace: true,
        },
      },
      sectionAppearance: {
        type: 'object',
        properties: {
          odk_looped: {
            type: 'boolean',
            ui: {
              noLabelSpace: true,
              widget: 'checkbox',
              widgetConfig: {
                enumSource: [
                  {
                    label: 'Make a Repeat',
                  },
                ],
              },
            },
          },
          onescreen: {
            type: 'boolean',
            ui: {
              noLabelSpace: true,
              widget: 'checkbox',
              widgetConfig: {
                enumSource: [
                  {
                    label: 'Display on One Screen',
                  },
                ],
              },
            },
          },
          odk_repeat_count: {
            type: 'string',
            ui: {
              columns: 9,
              label: 'Repeat Count',
              hidden: 'dx: {{$root.sectionAppearance.odk_looped}} !== true',
              // description: '',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
          btn_rcount: {
            type: 'button',
            ui: {
              columns: 3,
              hidden: 'dx: {{$root.sectionAppearance.odk_looped}} !== true',
              widget: 'add-rcount',
              showLabel: false,
            },
          },
        },
        ui: {
          legend: 'Appearance',
          noLabelSpace: true,
          widgetConfig: {
            collapsed: false,
          },
        },
      },
      sectionAdvanced: {
        type: 'object',
        properties: {
          odk_relevant: {
            type: 'string',
            ui: {
              columns: 9,
              label: 'Relevants',
              // description: '',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
          btn_relevant: {
            type: 'button',
            ui: {
              columns: 3,
              widget: 'add-relevant',
              showLabel: false,
            },
          },
          odk_note: {
            type: 'string',
            ui: {
              label: 'Internal comment',
              description: 'Internal description (note for yourself :))',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
        },
        ui: {
          legend: 'Advanced',
          noLabelSpace: true,
          widgetConfig: {
            collapsed: false,
          },
        },
      },
    },
    globalConfig: {
      constants: { // Global constant configuration, can be accessed by {{$const.userName}}
        lang: [],
        choice: [
          {
            lang: [],
          },
        ],
      },
    },
    value: {
      internalKey: '',
      type: '',
      odk_name: '',
      sectionLabel: {
        odk_label: [''],
      },
      sectionAppearance: {
        odk_looped: false,
        onescreen: false,
        odk_repeat_count: '',
        btn_rcount: '',
      },
      sectionAdvanced: {
        odk_relevant: '',
        btn_relevant: '',
        odk_note: '',
      },
    },
  },
}
