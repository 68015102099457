<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    mini-variant-width="60"
    :mini-variant.sync="mini"
    width="200"
    clipped
    dark
    v-bind="$attrs"
  >
    <v-list-item class="px-2">
      <v-list-item-title>
        Form elements
      </v-list-item-title>
      <v-btn
        icon
        small
        dark
        @click.stop="mini = !mini"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider />

    <v-list
      dense
      nav
    >
      <v-list-item
        v-for="item in QuestionTypes"
        :key="item.title"
        link
        @click="$parent.addQuestion(item.type)"
      >
        <v-list-item-icon>
          <v-tooltip
            v-if="mini"
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
              >
                {{ item.icon }}
              </v-icon>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>
          <v-icon v-else>
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  // import { mapState } from 'vuex'
  import QuestionTypes from '../questiontypes'

  export default {
    name: 'DesignQuestionsDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      drawer: true,
      mini: true,
      selectedQuestion: null,
      QuestionTypes,
    }),

    computed: {
      // ...mapState(['barColor', 'barImage']),

      // drawer: {
      //   get () {
      //     return this.$store.state.drawer
      //   },
      //   set (val) {
      //     this.$store.commit('SET_DRAWER', val)
      //   },
      // },

    },

    // watch: {
    //   '$vuetify.breakpoint.smAndDown' (val) {
    //     this.$emit('update:expandOnHover', !val)
    //   },
    // },

    created () {
      // this.isLocation()
      // this.getdLocationBlock()
    },

    mounted () {
      // console.log(this.$parent)
      // console.log('test')
    },

    methods: {

      // async getdLocationBlock () {
      //   const ocpuPack = this.$store.state.ocpu.ocpuPack
      //   const main = this.$store.state.main
      //   await this.$httpOcpu.post(`/${ocpuPack}/R/ocgetblocks/json`, {
      //     orgid: main.orgid,
      //     prjid: main.ap.prjid,
      //     formid: main.afid,
      //     bname: 'dBlocks',
      //   }).then(
      //     response => {
      //       // console.log(response)
      //       if (response.data.length > 0) {
      //         // Parse json string respons)e from ocpu
      //         const data = JSON.parse(response.data[0])
      //         // console.log(data)
      //         const active = data.filter(f => f.pack === 'oclocation' && f.selected === true)
      //         // console.log(active)
      //         if (active.length > 0) {
      //           this.mapactive = true
      //         }
      //       }
      //     })
      // },

    },
  }
</script>

<style lang="sass">

</style>
