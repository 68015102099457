<template>
  <div>
    <el-button
      type="info"
      disabled
      icon="el-icon-tickets"
      size="mini"
      circle
      @click="submit('list')"
    />
    <el-button
      type="primary"
      icon="el-icon-edit"
      size="mini"
      circle
      @click="submit('edit')"
    />
  </div>
</template>

<script>
  import ncformCommon from '@ncform/ncform-common'

  export default {
    mixins: [ncformCommon.mixins.vue.controlMixin],

    data () {
      return {
        caller: 'calculate',
      }
    },

    methods: {
      // you can handle the modelVal before $emit it (before this.$emit('input'))
      _processModelVal (modelVal) {
        return modelVal
      },
      submit (prop) {
        // console.log(prop)
        this.$store.commit('odkform/dialogAddCode', { caller: this.caller, value: true })
      },

    },
  }
</script>

<style scoped>
.my-custom-comp {
  text-align: left;
  line-height: 3;
}
</style>
