module.exports = {
  title: 'Date',
  icon: 'mdi-calendar-clock',
  class: 'item',
  // ==== SCHEMA =====
  schema: {
    type: 'object',
    properties: {
      internalKey: {
        type: 'number',
        ui: {
          hidden: true,
        },
      },
      type: {
        type: 'string',
        ui: {
          hidden: true,
        },
      },
      odk_name: {
        type: 'string',
        ui: {
          noLabelSpace: true,
          widgetConfig: {
            size: 'mini',
            clearable: true,
            updateOn: 'blur',
            compound: {
              prependLabel: 'Name',
            },
          },
        },
        rules: {
          required: {
            value: true,
            errMsg: 'Required!',
          },
          pattern: {
            value: '^[a-zA-Z0-9_]+$',
            errMsg: 'Only numbers, letters and underscore',
          },
        },
      },
      sectionLabel: {
        type: 'object',
        properties: {
          odk_label: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widget: 'textarea',
                widgetConfig: {
                  rows: 1,
                  size: 'mini',
                  clearable: true,
                  updateOn: 'blur',
                },
              },
              rules: {
                required: true,
              },
            },
            ui: {
              showLegend: false,
              noLabelSpace: true,
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
                disableCollapse: true,
              },
            },
          },
        },
        ui: {
          legend: 'Question label',
          noLabelSpace: true,
        },
      },
      sectionHint: {
        type: 'object',
        properties: {
          odk_hint: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widgetConfig: {
                  size: 'mini',
                  updateOn: 'blur',
                },
              },
            },
            ui: {
              showLegend: false,
              noLabelSpace: true,
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
                disableCollapse: true,
              },
            },
          },
        },
        ui: {
          legend: 'Hints',
          noLabelSpace: true,
          widgetConfig: {
            collapsed: true,
          },
        },
      },
      sectionAppearance: {
        type: 'object',
        properties: {
          odk_read_only: {
            type: 'boolean',
            ui: {
              noLabelSpace: true,
              widget: 'checkbox',
              widgetConfig: {
                enumSource: [
                  {
                    label: 'Read only',
                  },
                ],
              },
            },
          },
          odk_required: {
            type: 'boolean',
            ui: {
              noLabelSpace: true,
              widget: 'checkbox',
              widgetConfig: {
                enumSource: [
                  {
                    label: 'Required',
                  },
                ],
              },
            },
          },
          odk_required_message: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widgetConfig: {
                  size: 'mini',
                  updateOn: 'blur',
                  // compound: {
                  //   prependLabel: 'dx: {{$const.lang[i].code}}',
                  // },
                },
              },
            },
            ui: {
              label: 'Required message',
              showLegend: false,
              hidden: 'dx: {{$root.sectionAppearance.odk_required}} !== true',
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
              },
            },
          },
          date_time_widget: {
            type: 'string',
            value: 'date',
            ui: {
              label: 'Select Type',
              widget: 'radio',
              widgetConfig: {
                size: 'mini',
                enumSource: [
                  {
                    value: 'date',
                    label: 'Date',
                  },
                  {
                    value: 'dateTime',
                    label: 'Date/Time',
                  },
                  {
                    value: 'time',
                    label: 'Time',
                  },
                ],
                updateOn: 'blur',
              },
            },
            rules: {
              required: true,
            },
          },
          date_type: {
            type: 'string',
            // value: 'full-date',
            ui: {
              label: 'Date Type',
              widget: 'select',
              hidden: 'dx: {{$root.sectionAppearance.date_time_widget}} !== "date"',
              widgetConfig: {
                size: 'mini',
                enumSource: [
                  {
                    value: 'full-date',
                    label: 'Full date',
                  },
                  {
                    value: 'no-calendar',
                    label: 'No Calendar',
                  },
                  {
                    value: 'month-year',
                    label: 'Year and month',
                  },
                  {
                    value: 'year',
                    label: 'Year only',
                  },
                  {
                    value: 'coptic',
                    label: 'Coptic calendar',
                  },
                  {
                    value: 'ethiopian',
                    label: 'Ethiopian calendar',
                  },
                  {
                    value: 'islamic',
                    label: 'Islamic calendar',
                  },
                  {
                    value: 'bikram-sambat',
                    label: 'Bikram Sambat calendar',
                  },
                  {
                    value: 'myanmar',
                    label: 'Myanmar calendar',
                  },
                  {
                    value: 'persian',
                    label: 'Persian calendar',
                  },
                ],
                updateOn: 'blur',
              },
            },
          },
          datetime_type: {
            type: 'string',
            // value: 'full-date',
            ui: {
              label: 'Date Type',
              widget: 'select',
              hidden: 'dx: {{$root.sectionAppearance.date_time_widget}} !== "dateTime"',
              widgetConfig: {
                size: 'mini',
                enumSource: [
                  {
                    value: 'full-date',
                    label: 'Full date',
                  },
                  {
                    value: 'no-calendar',
                    label: 'No Calendar',
                  },
                ],
                updateOn: 'blur',
              },
            },
          },
        },
        ui: {
          legend: 'Appearance',
          noLabelSpace: true,
        },
      },
      sectionAdvanced: {
        type: 'object',
        properties: {
          odk_relevant: {
            type: 'string',
            ui: {
              columns: 9,
              label: 'Relevants',
              // description: '',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
          btn_relevant: {
            type: 'button',
            ui: {
              columns: 3,
              widget: 'add-relevant',
              showLabel: false,
            },
          },
          odk_constraint: {
            type: 'string',
            ui: {
              columns: 9,
              label: 'Constraint',
              // description: '',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
          btn_constraint: {
            type: 'button',
            ui: {
              columns: 3,
              widget: 'add-constraint',
              showLabel: false,
            },
          },
          odk_constraint_message: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widgetConfig: {
                  size: 'mini',
                  updateOn: 'blur',
                },
              },
            },
            ui: {
              label: 'Constraint message',
              showLegend: false,
              hidden: 'dx: {{$root.sectionAdvanced.odk_constraint}} === ""',
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
              },
            },
          },
          odk_note: {
            type: 'string',
            ui: {
              label: 'Internal comment',
              description: 'Internal description (note for yourself :))',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
        },
        ui: {
          legend: 'Advanced',
          noLabelSpace: true,
          widgetConfig: {
            collapsed: true,
          },
        },
      },
    },
    globalConfig: {
      constants: { // Global constant configuration, can be accessed by {{$const.userName}}
        lang: [],
        choice: [
          {
            lang: [],
          },
        ],
      },
    },
    value: {
      internalKey: '',
      type: '',
      odk_name: '',
      sectionLabel: {
        odk_label: [''],
      },
      sectionHint: {
        odk_hint: [''],
      },
      sectionAppearance: {
        odk_read_only: false,
        odk_required: false,
        odk_required_message: [''],
        date_time_widget: 'date',
        date_type: 'full-date',
        datetime_type: 'full-date',
      },
      sectionAdvanced: {
        odk_relevant: '',
        btn_relevant: '',
        odk_constraint: '',
        btn_constraint: '',
        odk_constraint_message: [''],
        odk_note: '',
      },
    },
  },
}
