module.exports = {
  title: 'Choose one',
  icon: 'mdi-checkbox-multiple-marked-circle',
  class: 'item',
  // ==== SCHEMA =====
  schema: {
    type: 'object',
    properties: {
      internalKey: {
        type: 'number',
        ui: {
          hidden: true,
        },
      },
      type: {
        type: 'string',
        ui: {
          hidden: true,
        },
      },
      odk_name: {
        type: 'string',
        ui: {
          noLabelSpace: true,
          widgetConfig: {
            size: 'mini',
            clearable: true,
            updateOn: 'blur',
            compound: {
              prependLabel: 'Name',
            },
          },
        },
        rules: {
          required: {
            value: true,
            errMsg: 'Required!',
          },
          pattern: {
            value: '^[a-zA-Z0-9_]+$',
            errMsg: 'Only numbers, letters and underscore',
          },
        },
      },
      sectionLabel: {
        type: 'object',
        properties: {
          odk_label: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widget: 'textarea',
                widgetConfig: {
                  rows: 1,
                  size: 'mini',
                  clearable: true,
                  updateOn: 'blur',
                },
              },
              rules: {
                required: true,
              },
            },
            ui: {
              showLegend: false,
              noLabelSpace: true,
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
                disableCollapse: true,
              },
            },
          },
        },
        ui: {
          legend: 'Question label',
          noLabelSpace: true,
        },
      },
      sectionHint: {
        type: 'object',
        properties: {
          odk_hint: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widgetConfig: {
                  size: 'mini',
                  updateOn: 'blur',
                },
              },
            },
            ui: {
              showLegend: false,
              noLabelSpace: true,
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
                disableCollapse: true,
              },
            },
          },
        },
        ui: {
          legend: 'Hints',
          noLabelSpace: true,
          widgetConfig: {
            collapsed: true,
          },
        },
      },
      sectionChoices: {
        type: 'object',
        properties: {
          choice_source: {
            type: 'string',
            value: 'upload',
            ui: {
              label: 'Choice Source',
              widget: 'radio',
              widgetConfig: {
                type: 'button',
                size: 'mini',
                enumSource: [
                  {
                    value: 'upload',
                    label: 'Upload',
                  },
                  {
                    value: 'manual',
                    label: 'Manual',
                  },
                  {
                    value: 'external',
                    label: 'External',
                  },
                ],
                updateOn: 'blur',
              },
            },
            rules: {
              required: true,
            },
          },
          btn_upload: {
            type: 'button',
            ui: {
              showLabel: false,
              columns: 3,
              hidden: 'dx: {{$root.sectionChoices.choice_source}} !== "upload"',
              widget: 'upload-choices',
            },
          },
          external_file: {
            type: 'string',
            ui: {
              // noLabelSpace: true,
              showLabel: false,
              hidden: 'dx: {{$root.sectionChoices.choice_source}} !== "external"',
              widgetConfig: {
                size: 'mini',
                clearable: true,
                updateOn: 'blur',
                compound: {
                  prependLabel: 'File name',
                },
              },
            },
            rules: {
              required: true,
            },
          },
          choices: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                odk_name: {
                  type: 'string',
                  ui: {
                    noLabelSpace: true,
                    placeholder: 'Name',
                    widgetConfig: {
                      size: 'mini',
                      updateOn: 'blur',
                      compound: {
                        prependLabel: 'Name',
                      },
                    },
                  },
                  rules: {
                    required: true,
                  },
                },
                odk_clabel: {
                  type: 'array',
                  value: [''],
                  items: {
                    type: 'string',
                    ui: {
                      label: 'dx: {{$const.choice[0].lang[i].full}}',
                      placeholder: 'Label',
                      widgetConfig: {
                        size: 'mini',
                        updateOn: 'blur',
                      },
                    },
                    rules: {
                      required: true,
                    },
                  },
                  ui: {
                    showLegend: false,
                    noLabelSpace: true,
                    widgetConfig: {
                      autoIdxToLabel: false,
                      disableAdd: true,
                      disableDel: true,
                      disableReorder: true,
                      disableItemCollapse: true,
                      disableCollapse: true,
                    },
                  },
                },
              },
              ui: {
                label: 'dx: {{$root.sectionChoices.choices[i].odk_name}} === "" ? "Choice " : {{$root.sectionChoices.choices[i].odk_name}}',
              },
            },
            ui: {
              showLegend: false,
              hidden: 'dx: {{$root.sectionChoices.choice_source}} !== "manual"',
              noLabelSpace: true,
              widgetConfig: {
                showOneIfEmpty: true,
                autoIdxToLabel: false,
              },
            },
          },
        },
        ui: {
          legend: 'Choices',
          noLabelSpace: true,
          widgetConfig: {
            collapsed: false,
          },
        },
      },
      sectionAppearance: {
        type: 'object',
        properties: {
          odk_read_only: {
            type: 'boolean',
            ui: {
              noLabelSpace: true,
              widget: 'checkbox',
              widgetConfig: {
                enumSource: [
                  {
                    label: 'Read only',
                  },
                ],
              },
            },
          },
          odk_required: {
            type: 'boolean',
            ui: {
              noLabelSpace: true,
              widget: 'checkbox',
              widgetConfig: {
                enumSource: [
                  {
                    label: 'Required',
                  },
                ],
              },
            },
          },
          odk_required_message: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widgetConfig: {
                  size: 'mini',
                  updateOn: 'blur',
                  // compound: {
                  //   prependLabel: 'dx: {{$const.lang[i].code}}',
                  // },
                },
              },
            },
            ui: {
              label: 'Required message',
              showLegend: false,
              hidden: 'dx: {{$root.sectionAppearance.odk_required}} !== true',
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
              },
            },
          },
          appearance_type: {
            type: 'string',
            value: 'none',
            ui: {
              label: 'Select appearances',
              widget: 'select',
              widgetConfig: {
                size: 'mini',
                enumSource: [
                  {
                    value: 'none',
                    label: 'None',
                  },
                  {
                    value: 'quick',
                    label: 'Quick',
                  },
                  {
                    value: 'minimal',
                    label: 'Minimal',
                  },
                  {
                    value: 'autocomplete',
                    label: 'Autocomplete',
                  },
                  {
                    value: 'columns',
                    label: 'Columns',
                  },
                  {
                    value: 'columns-n',
                    label: 'Columns (n)',
                  },
                  {
                    value: 'no-buttons',
                    label: 'No buttons',
                  },
                  {
                    value: 'likert',
                    label: 'Likert',
                  },
                  {
                    value: 'map',
                    label: 'Map',
                  },
                  {
                    value: 'image-map',
                    label: 'Image map',
                  },
                  {
                    value: 'custom',
                    label: 'Custom',
                  },
                ],
                updateOn: 'blur',
              },
            },
            rules: {
              required: true,
            },
          },
          odk_appearance: {
            type: 'string',
            ui: {
              label: 'Appearance',
              hidden: 'dx: {{$root.sectionAppearance.appearance_type}} !== "custom"',
              widgetConfig: {
                size: 'mini',
                clearable: true,
                updateOn: 'blur',
              },
            },
            rules: {
              required: true,
            },
          },
          appearance_column_n: {
            type: 'number',
            value: 2,
            ui: {
              label: 'Number of columns',
              hidden: 'dx: {{$root.sectionAppearance.appearance_type}} !== "columns-n"',
              widget: 'input-number',
              widgetConfig: {
                size: 'mini',
                min: 1,
                max: 9,
              },
            },
            rules: {
              required: true,
            },
          },
        },
        ui: {
          legend: 'Appearance',
          noLabelSpace: true,
          widgetConfig: {
            collapsed: true,
          },
        },
      },
      sectionAdvanced: {
        type: 'object',
        properties: {
          odk_relevant: {
            type: 'string',
            ui: {
              columns: 9,
              label: 'Relevants',
              // description: '',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
          btn_relevant: {
            type: 'button',
            ui: {
              columns: 3,
              widget: 'add-relevant',
              showLabel: false,
            },
          },
          odk_constraint: {
            type: 'string',
            ui: {
              columns: 9,
              label: 'Constraint',
              // description: '',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
          btn_constraint: {
            type: 'button',
            ui: {
              columns: 3,
              widget: 'add-constraint',
              showLabel: false,
            },
          },
          odk_constraint_message: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widgetConfig: {
                  size: 'mini',
                  updateOn: 'blur',
                },
              },
            },
            ui: {
              label: 'Constraint message',
              showLegend: false,
              hidden: 'dx: {{$root.sectionAdvanced.odk_constraint}} === ""',
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
              },
            },
          },
          odk_parameters: {
            type: 'string',
            ui: {
              label: 'Parameters',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
          odk_note: {
            type: 'string',
            ui: {
              label: 'Internal comment',
              description: 'Internal description (note for yourself :))',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
        },
        ui: {
          legend: 'Advanced',
          noLabelSpace: true,
          widgetConfig: {
            collapsed: true,
          },
        },
      },
    },
    globalConfig: {
      constants: { // Global constant configuration, can be accessed by {{$const.userName}}
        lang: [],
        choice: [
          {
            lang: [],
          },
        ],
      },
    },
    value: {
      internalKey: '',
      type: '',
      odk_name: '',
      sectionLabel: {
        odk_label: [''],
      },
      sectionHint: {
        odk_hint: [''],
      },
      sectionChoices: {
        choice_source: 'upload',
        external_file: '',
        choices: [
          {
            odk_name: '',
            odk_clabel: [''],
          },
        ],
      },
      sectionAppearance: {
        odk_read_only: false,
        odk_required: false,
        odk_required_message: [''],
        appearance_type: 'none',
        odk_appearance: '',
        appearance_column_n: 2,
      },
      sectionAdvanced: {
        odk_relevant: '',
        odk_constraint: '',
        odk_constraint_message: [''],
        odk_parameters: '',
        odk_note: '',
      },
    },
  },
}
