const type = [
  'text',
  'numeric',
  'datetime',
  'chooseone',
  'selectmultiple',
  'media',
  // 'barcode',
  'location',
  'calculate',
  'note',
  'range',
  'rank',
  'group',
  'metadata',
]

module.exports = type.map(type => ({ ...require('./' + type), type }))
