<template>
  <v-container
    id="formdesign"
    fluid
    class="pt-0"
  >
    <!-- Drawer - Form elements -->
    <questions-drawer v-model="questionsDrawer" />

    <v-navigation-drawer
      v-model="drawer"
      app
      mini-variant-width="15"
      :mini-variant.sync="settingsMini"
      width="400"
      clipped
      right
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card
        class="mx-auto mb-0 mt-0 overflow-y-auto"
        elevation="0"
      >
        <v-card-text>
          <v-col cols="12">
            <v-row
              v-if="selectedItem != null"
              v-model="selectedItem"
              class="mb-2"
            >
              <v-btn
                icon
                small
                dark
                color="warning"
                @click.stop="settingsMini = !settingsMini"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-icon
                class="mx-3"
                medium
              >
                {{ selectedItem.icon }}
              </v-icon>
              <!-- <span class="grey--text text--lighten-5 display-2 font-weight-light">{{ $parent.selectedItem.title }}</span> -->
              <span class="display-2 font-weight-light">{{ selectedItem.title }}</span>
              <v-spacer />
              <v-btn
                small
                :color="selectedItem.valid ? 'success' : 'warning'"
                dark
                @click="formValidate()"
              >
                {{ selectedItem.valid ? 'valid' : 'invalid' }}
                <v-icon
                  dark
                  right
                >
                  {{ selectedItem.valid ? 'mdi-checkbox-marked-outline' : 'mdi-alert-box-outline' }}
                </v-icon>
              </v-btn>
            </v-row>
            <v-divider />
            <v-row class="mt-2">
              <v-form
                ref="myForm"
              >
                <div>
                  <ncform
                    v-model="schemavalue"
                    :form-schema="formSchema"
                    :is-dirty.sync="isFormDirty"
                    form-name="myForm"
                    @change="onChange"
                  />
                </div>
              </v-form>
            </v-row>
          </v-col>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>

    <!-- ODK Form questions -->
    <v-row>
      <v-col
        cols="12"
      >
        <v-card
          class="mx-auto mt-4"
          outlined
          max-width="1020"
        >
          <!-- Form questions toolbar, buttons -->
          <v-app-bar
            absolute
            dense
            color="white"
            elevate-on-scroll
          >
            <v-toolbar-title>{{ $store.state.main.af.odksettings.title }}</v-toolbar-title>
            <v-spacer />

            <!-- Settings -->
            <v-spacer />
            <v-btn
              v-if="myRole() !== 'viewer'"
              :dark="odkFormUpdated"
              small
              class="mx-2"
              color="warning"
              elevation="0"
              :disabled="!odkFormUpdated"
              @click="odkFormSave({ft: 'main', q: 'full'})"
            >
              <v-icon
                left
                dark
              >
                mdi-content-save
              </v-icon>
              {{ $t('form-design.save') }}
            </v-btn>
            <v-btn
              small
              :dark="(odkFormSaved && treeData.length > 0 && !odkFormUpdated)"
              class="mx-2"
              color="primary"
              elevation="0"
              :disabled="!(odkFormSaved && treeData.length > 0 && !odkFormUpdated)"
              :loading="building"
              @click="buldForm()"
            >
              <v-icon left>
                mdi-file-excel
              </v-icon>
              {{ $t('form-design.build') }}
            </v-btn>
            <v-btn
              small
              :dark="(odkFormBuild && !odkFormSaved && !odkFormUpdated)"
              class="mx-2"
              color="secondary"
              elevation="0"
              :disabled="!(odkFormBuild && !odkFormSaved && !odkFormUpdated)"
              :loading="loading"
              @click="deployForm()"
            >
              <v-icon left>
                mdi-cloud-upload
              </v-icon>
              {{ $t('form-design.deploy') }}
            </v-btn>

            <v-menu
              open-on-hover
              bottom
              left
              min-width="200"
              offset-y
              origin="top right"
              transition="scale-transition"
            >
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  small
                  dark
                  icon
                  class="mx-2"
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item-group
                  color="primary"
                >
                  <v-list-item
                    v-for="(item, i) in menuitems"
                    :key="i"
                    @click="menuSelected(item.val)"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-app-bar>
          <v-card-text />
          <v-sheet
            id="scrolling-techniques-7"
            class="overflow-y-auto"
            :max-height="windowHeight - 160"
          >
            <v-container>
              <v-card-text>
                <v-row class="pb-8">
                  <v-col cols="12">
                    <tree-view
                      v-if="treeData"
                      ref="tree"
                      :value="treeData"
                      :title="$store.state.main.af.odksettings.title"
                      draggable
                      droppable
                      @settings="questionSelected"
                      @deleteAll="removeAllQuestionsRequest"
                      @deleteByPath="removeByPath"
                      @duplicateNode="duplicateThisNode"
                      @drop="dropped"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-container>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>

    <!-- DIALOGS -->
    <v-row justify="center">
      <!-- Form drafts version -->
      <v-dialog
        v-model="dialogDrafts"
        max-width="620"
      >
        <v-card class="pa-4">
          {{ $t('sections.drafts') }}
          <v-data-table
            :headers="headerDraft"
            dense
            :items="formList.draft"
            :sort-by="['form_title[0]', 'version[0]']"
            :sort-desc="[false, true]"
            item-key="vkey[0]"
            multi-sort
          >
            <!-- Action buttons -->
            <template v-slot:[`item.actions`]="{ item }">
              <!-- Load item -->
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    class="mr-3"
                    v-on="on"
                    @click="loadDraft(item)"
                  >
                    mdi-application-import
                  </v-icon>
                </template>
                <span> {{ $t('form-design.load-form') }} </span>
              </v-tooltip>
              <!-- Delete Item -->
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    color="primary"
                    :disabled="myRole() !== 'manager'"
                    v-on="on"
                    @click="deleteDraft({item: item, type: 'draft'})"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>{{ $t('form-design.delete-form') }}</span>
              </v-tooltip>
            </template>
            <!-- Query Details section -->
          </v-data-table>

          <validation-observer v-slot="{ handleSubmit }">
            <v-form @submit.prevent="handleSubmit(saveDraft)">
              <v-row class="mb-0">
                <v-col
                  class="pr-0"
                  cols="3"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('table-header.title')"
                  >
                    <v-text-field
                      v-model="dTitle"
                      :label="$t('form-design.draft-title')"
                      outlined
                      dense
                      required
                      hide-details
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="8"
                  class="pr-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('table-header.description')"
                  >
                    <v-text-field
                      v-model="dDesc"
                      :label="$t('table-header.description')"
                      outlined
                      dense
                      required
                      hide-details
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  class="px-0"
                  cols="auto"
                >
                  <v-btn
                    type="submit"
                    color="warning"
                    dark
                    icon
                  >
                    <v-icon>
                      mdi-content-save
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-row>
                  <v-spacer />
                  <v-btn
                    color="grey darken-1"
                    text
                    @click="dialogDrafts = false"
                  >
                    {{ $t('common.close') }}
                  </v-btn>
                </v-row>
              </v-card-actions>
            </v-form>
          </validation-observer>
        </v-card>
      </v-dialog>

      <!-- Load from Template -->
      <v-dialog
        v-model="dialogLoadTemplate"
        min-width="500"
        max-width="800"
      >
        <v-card class="pa-4">
          <v-card-title>
            <span class="text-h3">{{ $t('form-design.load-template') }}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-tabs
                color="deep-purple accent-4"
                right
              >
                <v-tab
                  v-for="type in formTypes"
                  :key="type.code"
                  @change="getFormList(type.code)"
                >
                  {{ type.title }}
                </v-tab>

                <v-spacer />
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('common.search')"
                  single-line
                />

                <v-tab-item
                  v-for="type in formTypes"
                  :key="type.code"
                >
                  <v-data-table
                    v-if="formList[type.code]"
                    :headers="headerTemplate"
                    dense
                    :items="formList[type.code]"
                    :sort-by="['form_section[0]', 'form_title[0]']"
                    :sort-desc="[false, true]"
                    item-key="vkey[0]"
                    multi-sort
                    :search="search"
                  >
                    <!-- Action buttons -->
                    <template v-slot:[`item.actions`]="{ item }">
                      <!-- Load item -->
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-icon
                            class="mr-3"
                            v-on="on"
                            @click="loadTheTemplate({ form: item, type: type.code })"
                          >
                            mdi-application-import
                          </v-icon>
                        </template>
                        <span>{{ $t('form-design.load-form') }}</span>
                      </v-tooltip>
                      <!-- Delete Item -->
                      <v-tooltip
                        v-if="type.code === 'tprivate'"
                        bottom
                      >
                        <template #activator="{ on }">
                          <v-icon
                            color="primary"
                            :disabled="!isOwner()"
                            v-on="on"
                            @click="deleteDraft({item: item, type: type.code})"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                        <span>{{ $t('form-design.delete-form') }}</span>
                      </v-tooltip>
                    </template>
                    <!-- Query Details section -->
                  </v-data-table>
                </v-tab-item>
              </v-tabs>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-spacer />
              <v-btn
                color="grey darken-1"
                text
                @click="dialogLoadTemplate = false"
              >
                {{ $t('common.close') }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Save as Template -->
      <v-dialog
        v-model="dialogSaveTemplate"
        min-width="500"
        max-width="800"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('form-design.save-template') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <validation-observer v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(saveTemplate)">
                  <v-row class="mb-0">
                    <v-col
                      class="pr-0"
                      cols="4"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        :name="$t('table-header.section')"
                      >
                        <v-select
                          v-model="dSection"
                          :items="tmplSection"
                          outlined
                          dense
                          hide-details
                          item-text="title"
                          item-value="name"
                          :label="$t('form-design.select-section')"
                          :error-messages="errors"
                          return-object
                          @change="sectionSelected"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="8"
                    >
                      <validation-provider
                        v-if="customSection"
                        v-slot="{ errors }"
                        rules="required"
                        :name="$t('table-header.section')"
                      >
                        <v-text-field
                          v-model="dcSection"
                          :label="$t('table-header.section')"
                          outlined
                          dense
                          required
                          hide-details
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        :name="$t('table-header.title')"
                      >
                        <v-text-field
                          v-model="dTitle"
                          :label="$t('form-design.templ-title')"
                          outlined
                          dense
                          required
                          hide-details
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        :name="$t('table-header.description')"
                      >
                        <v-textarea
                          v-model="dDesc"
                          :label="$t('table-header.description')"
                          outlined
                          dense
                          required
                          hide-details
                          rows="2"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="dPrivate"
                        dense
                        persistent-hint
                        :hint="`${dPrivate ? $t('form-design.open') : $t('form-design.shared')} `"
                        :label="$t('form-design.keep-private')"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-spacer />
                    <v-btn
                      class="mr-3"
                      text
                      @click="dialogSaveTemplate = false"
                    >
                      {{ $t('common.close') }}
                    </v-btn>
                    <v-btn
                      color="success"
                      text
                      class="mr-3"
                      type="submit"
                    >
                      {{ $t('common.save') }}
                    </v-btn>
                  </v-row>
                </form>
              </validation-observer>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Form Translations -->
      <v-dialog
        v-model="dialogLangs"
        persistent
        max-width="450"
      >
        <v-card>
          <v-card-title>{{ $t('form-design.manage-translation') }}</v-card-title>
          <!-- Default Languages -->
          <v-list
            two-line
            subheader
          >
            <v-list-item
              v-for="lang in defaultLang"
              :key="lang.code"
              class="pl-1"
              link
            >
              <v-list-item-icon class="mx-0 mb-0 mt-1">
                <v-btn
                  icon
                  color="secondary"
                  dark
                  readonly
                >
                  <v-icon color="warning">
                    mdi-heart
                  </v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ lang.full }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('form-design.def-lang') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!-- Other Translations -->
          <v-list
            two-line
            subheader
          >
            <v-list-item
              v-for="lang in otherLang"
              :key="lang.code"
              class="pl-1"
              link
            >
              <v-list-item-icon class="mx-0 mb-0 mt-1">
                <v-btn
                  icon
                  color="secondary"
                  dark
                  ripple
                  @click="defaultTranslation(lang.code)"
                >
                  <v-icon color="warning">
                    mdi-heart-outline
                  </v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ lang.full }}
                </v-list-item-title>
                <v-list-item-subtitle>Completed 01% (1 / 100)</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  dark
                  ripple
                  @click.stop="removeTranslationRequest(lang.code)"
                >
                  <v-icon color="primary">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-card-actions class="pa-2">
            <v-btn
              small
              text
              class="mr-2"
              @click.stop="dialogAdd = true"
            >
              {{ $t('form-design.add-trans') }}
            </v-btn>
            <v-spacer />
            <v-btn
              small
              text
              @click="dialogLangs = false"
            >
              {{ $t('common.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Add Translation Dialog -->
      <v-dialog
        v-model="dialogAdd"
        persistent
        max-width="320"
      >
        <v-card>
          <v-card-title class="headline font-weight-regular blue-grey white--text py-2">
            {{ $t('form-design.add-ntrans') }}
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="newLang"
              :items="allLangs"
              :hint="(newLang.length) === 0 ? $t('form-design.no-trans') : `${newLang.label} (${newLang.code})`"
              item-text="label"
              item-value="code"
              :label="$t('form-design.select-trans')"
              persistent-hint
              return-object
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="dialogAdd = false"
            >
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
              text
              @click="addTranslation(newLang)"
            >
              {{ $t('common.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Confirmation Dialog -->
      <v-dialog
        v-model="dialogConfirm.active"
        persistent
        max-width="320"
      >
        <v-card>
          <v-card-title class="headline font-weight-regular primary white--text py-2">
            {{ dialogConfirm.title }}
          </v-card-title>
          <v-card-text>
            {{ dialogConfirm.text }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="grey darken-1"
              text
              @click="dialogNotConfirmed"
            >
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
              color="warning"
              text
              @click="dialogConfirmed"
            >
              {{ $t('common.agree') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- ODK Code Editor Dialog -->
      <v-dialog
        v-if="$store.state.odkform.dialogAddCode.value"
        v-model="$store.state.odkform.dialogAddCode.value"
        persistent
        max-width="620"
      >
        <v-card>
          <v-card-title class="headline font-weight-regular primary white--text py-2">
            {{ $t('form-design.code-editor') }}
          </v-card-title>
          <v-card-text>
            <v-textarea
              ref="textarea"
              v-model="code.named"
              clearable
              :label="$t('form-design.code')"
              auto-grow
              outlined
              rows="3"
              row-height="17"
            />
            <v-card
              v-if="$store.state.odkform.dialogAddCode.caller !== 'calculate'"
              class="pa-2"
              outlined
              tile
            >
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="selectedVar"
                    :items="questVarList"
                    outlined
                    dense
                    hide-details
                    item-text="label"
                    item-value="internalKey"
                    :label="$t('form-design.select-node')"
                    return-object
                    @change="questVarSelected"
                  />
                </v-col>
                <v-col>
                  <v-select
                    v-model="selectedOperand"
                    :items="operands"
                    outlined
                    dense
                    :disabled="selectedVar.type === 'selectmultiple' || Object.keys(selectedVar).length === 0 "
                    hide-details
                    item-text="code"
                    item-value="code"
                    :label="$t('form-design.operand')"
                    return-object
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-if="selectedVar.type !== 'chooseone' && selectedVar.type !== 'selectmultiple'"
                    v-model="varValue"
                    outlined
                    hide-details
                    dense
                    :label="selectedVar.type"
                    append-outer-icon="mdi-pencil-plus"
                    @click:append-outer="addCode"
                  />
                  <v-select
                    v-else
                    v-model="varValue"
                    :items="choiceVarList"
                    outlined
                    dense
                    hide-details
                    item-text="odk_name"
                    item-value="odk_name"
                    :label="$t('form-design.select-choice')"
                    return-object
                    append-outer-icon="mdi-pencil-plus"
                    @change="choiceSelected"
                    @click:append-outer="addCode"
                  />
                </v-col>
              </v-row>
            </v-card>
            <v-card
              v-if="$store.state.odkform.dialogAddCode.caller !== 'calculate'"
              class="pa-1 mt-1"
              outlined
              tile
            >
              <v-row class="ma-1">
                <v-btn
                  v-for="button in editBtn"
                  :key="button.label"
                  dark
                  :color="button.color"
                  class="mr-2"
                  @click="execInsertText( button.clicktxt )"
                >
                  {{ button.label }}
                </v-btn>
              </v-row>
            </v-card>
            <!-- For calculate -->
            <v-card
              v-if="$store.state.odkform.dialogAddCode.caller === 'calculate'"
              class="pa-2 mt-1"
              outlined
              tile
            >
              <v-row>
                <v-col cols="4">
                  <v-select
                    v-model="ffunction"
                    :items="formfunctions"
                    outlined
                    dense
                    hide-details
                    item-text="func"
                    item-value="code"
                    :label="$t('form-design.select-func')"
                    return-object
                  />
                </v-col>
                <v-col cols="7">
                  {{ ffunction.details }}
                </v-col>
                <v-col cols="1">
                  <v-btn
                    icon
                    dark
                    ripple
                    class="pr-4"
                    @click.stop="execInsertText(ffunction.code)"
                  >
                    <v-icon color="grey">
                      mdi-pencil-plus
                    </v-icon>
                  </v-btn>
                </v-col>
                <div class="mx-3">
                  {{ ffunction.more }}
                </div>
              </v-row>
            </v-card>
            <!-- For calculate -->
            <v-card
              v-if="$store.state.odkform.dialogAddCode.caller === 'calculate'"
              class="pa-2 mt-1"
              outlined
              tile
            >
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="selectedVar"
                    :items="questVarList"
                    outlined
                    dense
                    hide-details
                    item-text="label"
                    item-value="internalKey"
                    :label="$t('form-design.select-node')"
                    return-object
                    @change="questVarSelected"
                  />
                </v-col>
                <v-col>
                  <v-select
                    v-model="selectedOperand"
                    :items="operands"
                    outlined
                    dense
                    :disabled="selectedVar.type === 'selectmultiple' || Object.keys(selectedVar).length === 0 "
                    hide-details
                    item-text="code"
                    item-value="code"
                    :label="$t('form-design.operand')"
                    return-object
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-if="selectedVar.type !== 'chooseone' && selectedVar.type !== 'selectmultiple'"
                    v-model="varValue"
                    outlined
                    hide-details
                    dense
                    :label="selectedVar.type"
                    append-outer-icon="mdi-pencil-plus"
                    @click:append-outer="addCode"
                  />
                  <v-select
                    v-else
                    v-model="varValue"
                    :items="choiceVarList"
                    outlined
                    dense
                    hide-details
                    item-text="odk_name"
                    item-value="odk_name"
                    :label="$t('form-design.select-choice')"
                    return-object
                    append-outer-icon="mdi-pencil-plus"
                    @change="choiceSelected"
                    @click:append-outer="addCode"
                  />
                </v-col>
              </v-row>
            </v-card>
            <!-- For calculate -->
            <v-card
              v-if="$store.state.odkform.dialogAddCode.caller === 'calculate'"
              class="pa-2 mt-1"
              outlined
              tile
            >
              <v-row>
                <v-col
                  cols="2"
                >
                  <v-select
                    v-model="selectedOperand"
                    :items="operands"
                    outlined
                    dense
                    hide-details
                    item-text="code"
                    item-value="code"
                    :label="$t('form-design.operand')"
                    return-object
                  />
                </v-col>
                <v-col>
                  <v-btn
                    v-for="button in calcbtn"
                    :key="button.label"
                    dark
                    :color="button.color"
                    class="mr-1"
                    @click="execInsertText( button.clicktxt )"
                  >
                    {{ button.label }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="grey darken-1"
              text
              @click="$store.commit('odkform/dialogAddCode', { caller: '', value: false })"
            >
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
              color="warning"
              text
              @click="moveCode"
            >
              {{ $t('form-design.use-code') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Upload Choices Dialog -->
      <v-dialog
        v-if="$store.state.odkform.dialogUplChoice.value"
        v-model="$store.state.odkform.dialogUplChoice.value"
        persistent
        max-width="500"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('common.upload') }}</span>
          </v-card-title>
          <v-card-text>
            <validation-observer v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(uploadChoiceList)">
                <v-row>
                  <v-col
                    cols="12"
                    class="pt-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="CSV file"
                      rules="required"
                    >
                      <v-file-input
                        v-model="choiceFile"
                        class="mx-4"
                        :error-messages="errors"
                        :label="$t('form-design.select-file')"
                        dense
                        outlined
                        full-width
                        accept=".csv"
                        @change="parseFile()"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col>
                    <v-simple-table
                      v-if="parsed"
                      dense
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              name
                            </th>
                            <th
                              v-for="(lang, l) in languages"
                              :key="l"
                              class="text-left"
                            >
                              {{ lang.label }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, i) in content.data"
                            :key="i"
                          >
                            <td>{{ item.name }}</td>
                            <td
                              v-for="(lang, l) in languages"
                              :key="l"
                            >
                              {{ item[lang.label] }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-btn
                    color="warning"
                    text
                    @click="getChoiceTemplate()"
                  >
                    {{ $t('form-design.choice-template') }}
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    text
                    @click="$store.commit('odkform/dialogUplChoice', { caller: '', value: false })"
                  >
                    {{ $t('common.cancel') }}
                  </v-btn>
                  <v-btn
                    color="success"
                    text
                    type="submit"
                    :disabled="!parsed"
                  >
                    {{ $t('common.upload') }}
                  </v-btn>
                </v-row>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- New SnackBarCard -->
    <snack-bar-card :snackbar="snackBar" />
  </v-container>
</template>

<script>
  /**
   * LOGIC:
   * When mounted run updateTranslations() add translations into schema and Model (data)
   * run getFormList() and Return list of all saved by saveFormDetails() json files from OCPU
   */
  import Vue from 'vue'
  import axios from 'axios'
  import Papa from 'papaparse'
  import TreeView from './components/CustomTree.vue'

  import QuestionsDrawer from './components/QuestionsDrawer'
  // import SettingsDrawer from './components/SettingsDrawer'

  // import PrettyPrint from './components/pretty-print'
  import langs from './components/langs.json'
  import formfunctions from './components/form-functions.json'

  // import flattenObject from './components/flattenObject'
  import userAccess from '@/mixins/user-access'
  import formDesign from './components/form-design'
  import formCodeBuilder from './components/form-code-builder'
  import odkForms from '@/mixins/forms'
  import SnackBarCard from '@/components/SnackbarCard'

  import Vuetify from 'vuetify'

  import { extend } from 'vee-validate'

  //
  import Element from 'element-ui'
  import vueNcform from '@ncform/ncform'
  import 'element-ui/lib/theme-chalk/index.css'
  import ncformStdComps from '@ncform/ncform-theme-elementui'
  import AddConstraint from './components/AddConstraint'
  import AddRelevant from './components/AddRelevant'
  import AddRcount from './components/AddRcount'
  import AddCalculate from './components/AddCalculate'
  import UploadChoices from './components/UploadChoices'

  extend('positive', value => {
    return value >= 1 && value <= 99
  })

  Vue.use(Vuetify)
  Vue.use(Element)
  Vue.use(vueNcform, {
    extComponents: Object.assign(ncformStdComps, {
      AddConstraint,
      AddRelevant,
      AddRcount,
      AddCalculate,
      UploadChoices,
    }),
    lang: 'en-en',
  })

  export default {
    components: {
      QuestionsDrawer,
      SnackBarCard,
      'tree-view': TreeView,
      // 'pretty-print': PrettyPrint,
      // 'papa-parse': Papa,
    },

    mixins: [
      // flattenObject,
      formDesign,
      formCodeBuilder,
      odkForms,
      userAccess,
    ],

    data: () => ({

      drawer: true,
      mini: true,
      isFormDirty: false,
      selectedItem: {},
      schemavalue: {},

      qTypesUpdated: [],

      formfunctions,

      odkFormUpdated: true,
      odkFormSaved: false,
      odkFormBuild: false,

      languages: [{ code: 'en', default: true, done: 0, label: 'English', full: 'English (en)' }],

      // modelValues: [],
      myValues: [],
      myValueIndex: '',
      currentVal: '',
      schema: {
        type: 'object',
        properties: {},
        globalConfig: {},
      },
      formSchema: {
        type: 'object',
        properties: {},
        globalConfig: {},
      },
      internalKey: '',
      formValid: false,

      dSection: {
        title: '',
        name: '',
      },
      dcSection: '',
      customSection: false,
      dTitle: '',
      dDesc: '',
      dPrivate: true,

      formTypeSelected: 'tprivate',

      formList: { draft: null, tprivate: null, tpublic: null, tglobal: null },
      draftForms: null,
      templateForms: null,
      groupForTmpl: {},
      choiceFile: null,

      // Dialogs
      dialogDrafts: false,
      dialogLoadTemplate: false,
      dialogSaveTemplate: false,
      dialogLangs: false,
      dialogAdd: false,
      dialogUploadForm: false,
      dialogConfirm: {
        active: false,
        type: '',
        title: '',
        text: '',
        data: null,
      },
      file: '',
      content: [],
      parsed: false,
      readyUpload: false,
      // parcedChoices: null,

      building: false,
      loading: false,

      search: '',

      newLang: [],

      questionsDrawer: true,
      settingsDrawer: true,
      settingsMini: true,

      treeData: [],
      windowHeight: window.innerHeight,

      // New snackbar notification
      snackBar: {
        type: 'success',
        mode: 'multi-line',
        direction: 'top center',
        timeout: 3000,
        title: '',
        text: '',
        visible: false,
      },
    }),

    computed: {
      defaultLang () {
        return this.languages.filter(l => l.default === true)
      },

      otherLang () {
        return this.languages.filter(l => l.default === false)
      },

      allLangs () {
        return langs
      },

      calcbtn () {
        const btn = this.editBtn.filter(
          btn => btn.calc === 1,
        )
        return btn
      },

      menuitems () {
        return [
          { text: this.$t('form-design.manage-translation'), icon: 'mdi-web', val: 'dialogLangs' },
          { text: this.$t('form-design.download-xlsform'), icon: 'mdi-file-download', val: 'downloadForm' },
          { text: this.$t('form-design.saved-drafts'), icon: 'mdi-file-multiple', val: 'drafts' },
          { text: this.$t('form-design.load-template'), icon: 'mdi-file-multiple-outline', val: 'loadTemplate' },
          { text: this.$t('form-design.save-template'), icon: 'mdi-file-plus-outline', val: 'saveTemplate' },
        ]
      },

      tmplSection () {
        return [
          { title: this.$t('form-design.roster'), name: 'roster' },
          { title: this.$t('form-design.general'), name: 'general' },
          { title: this.$t('form-design.location'), name: 'location' },
          { title: this.$t('form-design.custom'), name: 'custom' },
        ]
      },

      /**
      // choiceLabels () {
      //   const result = this.languages.map(l => ({
      //     id: l.id,
      //     roleid: user.roleid,
      //     role: user.role,
      //     name: user.name,
      //     access: (user.id === owneruid) ? 'owner' : (user.id === cStaff.uuid) ? 'current' : 'otheruser',
      //     type: this.userType(user.id),
      //   }))
      //   const labels = this.languages.map(obj => {
      //     id: user.id,
      //     roleid: user.roleid,
      //     role: user.role,
      //     // if (obj.id === 'odkform') {
      //     //   Object.assign(obj, { disabled: true })
      //     // } else {
      //     //   Object.assign(obj, { disabled: false })
      //     // }
      //     if (obj.id === 'odkform') {
      //       obj.disabled = true
      //     } else {
      //       obj.disabled = false
      //     }
      //   })
      //   return (labels)
      //   // `item.${lang.label}`
      // },
      */

      formTypes () {
        return [
          // { code: 'main', title: 'Main', selected: false },
          // { code: 'draft', title: 'Draft', selected: false },
          { code: 'tprivate', title: this.$t('form-design.private'), selected: true },
          { code: 'tpublic', title: this.$t('form-design.public'), selected: false },
          { code: 'tglobal', title: this.$t('form-design.global'), selected: false },
        ]
      },

      headerTemplate () {
        return [
          { text: this.$t('table-header.section'), value: 'form_section' },
          { text: this.$t('table-header.title'), value: 'form_title' },
          { text: this.$t('table-header.description'), value: 'form_desc' },
          { text: this.$t('oth.lang'), value: 'lang' },
          { text: this.$t('table-header.actions'), value: 'actions', sortable: false },
        ]
      },

      headerDraft () {
        return [
          { text: this.$t('table-header.title'), value: 'form_title' },
          { text: this.$t('table-header.description'), value: 'form_desc' },
          { text: this.$t('common.version'), value: 'version' },
          { text: this.$t('table-header.actions'), value: 'actions', sortable: false },
        ]
      },

    },

    watch: {
      '$store.state.odkform.dialogUplChoice.value': function () {
        this.choiceFile = null
        this.content = []
        this.parsed = false
        // console.log('watching, dialogAddCode: ' + this.$store.state.odkform.dialogAddCode.value)
        const caller = this.$store.state.odkform.dialogUplChoice.caller
        const schm = JSON.parse(JSON.stringify(this.schemavalue))
        switch (caller) {
          case 'choices':
            this.code.named = schm.sectionAppearance.odk_repeat_count
            break
          default:
            this.code.named = ''
        }
      },
    },

    created () {
      this.getDetails({ form: {}, type: 'main' })

      window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    // Works even when closing the page (https://stackoverflow.com/a/56551646)
    beforeRouteLeave (to, from, next) {
      // If the odkForm is updated and the user did not confirm leave,
      // prevent losing unsaved changes by canceling navigation
      if (this.confirmStayInDirtyForm()) {
        next(false)
      } else {
        // Navigate to next view
        next()
      }
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },

    mounted () {
      this.getFormList('draft')
      if (this.myRole() === 'viewer') {
        this.snackBar = {
          type: 'warning',
          mode: 'multi-line',
          timeout: 4500,
          title: 'View only',
          text: 'You do not have enough permission to save any changes to this form',
          visible: true,
        }
      }
    },

    methods: {

      // Function to write changes into myValues
      async onChange (value) {
        // console.log(value)

        // Find active question
        let found = { node: {}, index: null, parent: null, path: [] }
        this.$refs.tree.walkTreeData((node, index, parent, path) => {
          if (node.active === true) {
            found = { node, index, parent, path }
          }
        })

        const myVal = this.$ncformGetValue('myForm', { ignoreHiddenField: false })
        const myValString = JSON.stringify(myVal)
        // For testing purpose
        // let secondKey = ''
        // if (this.currentVal !== '') {
        //   const val = JSON.parse(this.currentVal)
        //   // console.log(val.internalKey)
        //   secondKey = val.internalKey
        // } else {
        //   // console.log('empty')
        //   secondKey = 'empty'
        // }
        // const Keys = myVal.internalKey === secondKey ? `same: ${secondKey}` : `myValue: ${myVal.internalKey} previous (current): ${secondKey}`
        // console.log(Keys)

        // If first select no need to change
        if (found.node.fresh) {
          this.currentVal = myValString
          found.node.fresh = false
        }
        if (this.currentVal !== myValString) {
          let i
          if (this.myValueIndex !== -1) {
            i = this.myValueIndex
          } else {
            i = this.myValues.findIndex(x => x.internalKey === myVal.internalKey.internalKey)
          }
          // console.log('changing?')
          // console.log(value)
          // const q = this.$refs.tree.getNodeByPath(prop.npath)
          const names = []
          JSON.stringify(this.myValues, (key, value) => {
            if (key === 'odk_name') names.push(value)
            return value
          })
          // console.log(names)
          this.myValues.splice(i, 1, value.formValue)
          this.currentVal = JSON.stringify(this.$ncformGetValue('myForm', { ignoreHiddenField: false }))

          this.odkFormUpdated = true

          // Update label in treeData TODO addLabelToTree() also can be used
          if (value.paths.includes('sectionLabel.odk_label')) {
            this.$refs.tree.walkTreeData((node) => {
              if (node.active === true) {
                // node.text = (myVal.sectionLabel.odk_label[0] !== '')
                //   ? myVal.sectionLabel.odk_label[0]
                //   : `${myVal.odk_name} (var)`
                node.settings.odk_label = myVal.sectionLabel.odk_label
              }
            })
          }
          // Update choices in treeData TODO addChoiceToTree() also can be used
          if (value.paths.includes('sectionChoices.choices') ||
            value.paths.includes('sectionChoices.external_file') ||
            value.paths.includes('sectionChoices.choice_source')) {
            // console.log('updateChoiceList')
            this.$refs.tree.walkTreeData((node) => {
              if (node.active === true) {
                node.settings.choiceList = myVal.sectionChoices.choices
                node.settings.choiceFile = myVal.sectionChoices.external_file
                node.settings.choiceSource = myVal.sectionChoices.choice_source
              }
            })
            // this.addChoiceToTree()
          }
          // Validation
          await Vue.nextTick()
          await Vue.nextTick()
          this.$refs.tree.walkTreeData((node) => {
            if (node.active === true) {
              this.$ncformValidate('myForm').then(data => {
                node.valid = data.result
              })
            }
          })
          // if (typeof value.itemValue === 'boolean') found.node.fresh = true
        } else {
          // do nothing
          // console.log('No change')
        }
      },
      formValidate () {
        this.$ncformValidate('myForm')
      },

      // Update ODK Form details
      async getDetails (prop) {
        // console.log(prop)
        const getformdetails = () => {
          //
          const ocpuPack = this.$store.state.ocpu.ocpuPack
          return new Promise((resolve, reject) => {
            this.$httpOcpu
              .post(`/${ocpuPack}/R/getfdetails/json`, {
                formsettings: JSON.stringify(prop.form),
                orgid: this.$store.state.main.orgid,
                prjid: this.$store.state.main.ap.prjid,
                formid: this.$store.state.main.afid,
                formtype: prop.type,
              })
              .then(r => {
                // console.log(r.data)
                return resolve({ message: r.data.message[0], data: r.data.fdetails[0] })

                // resolve({ message: 'aha', data: 'foo' })
              })
              .catch(error => {
                // console.log(error)
                return reject(error.message)
              })
          })
        }

        const r = await getformdetails()
        // console.log(r)
        // If ocpu has a saved form and result is 'Success' update vuex details
        // console.log(prop.type)
        if (r.message === 'Success') {
          const fdetails = JSON.parse(r.data)
          // console.log(fdetails)
          if (prop.type === 'draft' || prop.type === 'main') {
            this.myValues = fdetails.values
            this.treeData = fdetails.questions
            this.languages = fdetails.languages
          } else if (['tprivate', 'tpublic', 'tglobal'].includes(prop.type)) {
            return (fdetails)
            // console.log(fdetails)
          }
        }

        await this.updateTranslations()
        const activeQuestion = this.treeData.filter(
          question => question.active === true,
        )
        // console.log(activeQuestion.length)
        if (activeQuestion.length > 0) {
          this.questionSelected(activeQuestion[0])
        }
      },

      async addQuestion (prop) {
        // console.log(prop)
        // this.sortModel()

        this.formValid = false
        // this.settingsMini = true
        const epochedt = Math.round((new Date().getTime() / 100))
        // add '1' to the start in case slice results leading 0
        const internalKeyS = '1' + epochedt.toString().slice(3)
        const internalKey = parseInt(internalKeyS)
        this.internalKey = internalKey
        // console.log(internalKey)
        const quest = this.qTypesUpdated.filter(
          question => question.type === prop,
        )
        // clone with JSON parse and stringify: https://stackoverflow.com/a/23481096
        // also another way to (not deep) clone: const clone = myArray.slice(0);
        const myModel = JSON.parse(JSON.stringify(quest[0]))
        const typeshort = myModel.type.slice(0, -(myModel.type.length - 3))
        // console.log(myModel)

        const selectedvalue = myModel.schema.value
        selectedvalue.internalKey = internalKey
        selectedvalue.odk_name = `${typeshort}_${internalKey}`
        // selectedvalue.sectionLabel.odk_label[0] = `Label for ${myModel.type}_${internalKey}`
        selectedvalue.type = myModel.type
        this.myValues.push(selectedvalue)

        const groupType = quest[0].class === 'odkgroup'
        const myQuestion = {
          internalKey: internalKey,
          active: false,
          fresh: true,
          $droppable: groupType,
          draggable: true,
          icon: quest[0].icon,
          odk_name: `${typeshort}_${internalKey}`,
          // odk_label: [],
          settings: {
            icon: quest[0].icon,
            odk_label: [],
            choiceList: [],
            displayChoice: 'none',
            choiceSource: '',
            choiceFile: '',
          },
          type: quest[0].type,
          title: quest[0].title,
          class: quest[0].class,
          text: `${typeshort}_${internalKey} (var)`,
          valid: false,
          children: groupType ? [] : undefined, // children should come as last element
        }
        // console.log(myQuestion.settings)

        // Find active node
        let found = { node: {}, index: null, parent: null, path: [] }
        this.$refs.tree.walkTreeData((node, index, parent, path) => {
          if (node.active === true) {
            found = { node, index, parent, path }
            node.active = false
          }
        })

        myQuestion.active = true
        const apath = []
        if (found.path.length > 1) {
          const position = found.path[found.path.length - 1] // since it starts with 0 we need -1
          // Convert a JavaScript string in dot notation into an object reference
          // revized code from https://stackoverflow.com/a/69459511 , here I'm using array
          const aget = (t, path) => path.reduce((r, k) => r?.[k], t)
          for (let k = 0; k + 1 < found.path.length; k++) {
            apath.push(found.path[k], 'children')
          }
          aget(this.treeData, apath).splice(position + 1, 0, myQuestion)
        } else {
          // if selected question is in the root
          this.treeData.splice(found.path[0] + 1, 0, myQuestion)
        }
        // console.log(this.treeData)

        this.odkFormUpdated = true
        // console.log(JSON.parse(JSON.stringify(this.myValues)))

        this.questionSelected(myQuestion)
      },

      // ==== QUESTION IS SELECTED ===
      async questionSelected (question) {
        // console.log('questionSelected')
        // console.log(question)
        // console.log(this.qTypesUpdated)
        this.settingsMini = false
        // Make question selected
        this.$refs.tree.walkTreeData((q) => {
          q.active = false
        })
        question.active = true
        this.selectedItem = question

        // Select question from updated question types
        const filtered = this.qTypesUpdated.filter(
          q => q.type === question.type,
        )
        // console.log(filtered[0])
        const foo = JSON.parse(JSON.stringify(filtered[0]))
        // console.log(foo)
        this.formSchema = foo.schema
        // console.log(JSON.parse(JSON.stringify(this.myValues)))
        const index = this.myValues.findIndex(x => x.internalKey === question.internalKey)
        // if (index !== -1) this.schemavalue = this.myValues[index]
        if (index !== -1) {
          this.myValueIndex = index
          this.schemavalue = this.myValues[index]
        }
      },
      // Question unselected
      questionUnselected () {
        this.schemavalue = {}
        // clear schema
        this.formSchema.type = 'object'
        this.formSchema.properties = {}
        this.settingsMini = true
        this.$refs.tree.walkTreeData((q) => {
          q.active = false
        })
      },

      // mark form as updated if question's order is changed
      dropped () {
        this.odkFormUpdated = true
      },

      // Save form details as a json file to OCPU
      sectionSelected (item) {
        // console.log(item)
        item.name === 'custom' ? this.customSection = true : this.customSection = false
      },

      // upload choices from file
      // https://serversideup.net/previewing-a-csv-file-with-vuejs-and-papaparse/
      parseFile () {
        this.content = []
        this.parsed = false
        Papa.parse(this.choiceFile, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            // console.log(results)
            this.content = results
            this.parsed = true
          }.bind(this),
        })
      },
      async uploadChoiceList () {
        // console.log(this.content.meta.fields)
        const labels = []
        this.languages.forEach((item, i) => {
          labels[i] = item.label
        })
        // console.log(labels)
        const found = labels.some(r => this.content.meta.fields.includes(r))
        if (this.content.meta.fields.includes('name') && found) {
          const choices = []
          this.content.data.forEach((el, i) => {
            choices[i] = {}
            choices[i].odk_name = el.name
            const text = []
            labels.forEach((label, l) => {
              el[label] !== undefined ? text.push(el[label]) : text.push('')
            })
            choices[i].odk_clabel = text
            this.$store.commit('odkform/dialogUplChoice', { caller: '', value: false })
            // console.log(text)
          })
          // console.log(choices)
          const schm = JSON.parse(JSON.stringify(this.schemavalue))
          schm.sectionChoices.choices = choices
          await Vue.nextTick()
          this.schemavalue = schm
          // After assign value onchange will be triggered make firstSelect = false to keep changes
          this.firstSelect = false
          // TODO Check this
          this.$store.commit('odkform/dialogAddCode', { caller: '', value: false })
          this.addChoiceToTree()
        } else {
          const col = found ? 'name' : 'label'
          this.snackBar = {
            type: 'error',
            mode: 'multi-line',
            timeout: 3000,
            title: `${this.$t('common.error')}`,
            text: `${col} column is missing`,
            visible: true,
          }
        }
      },
      getChoiceTemplate () {
        const labels = []
        this.languages.forEach((item, i) => {
          // console.log(item)
          labels[i] = item.label
        })
        const text = `name,${labels}`

        // https://www.raymondcamden.com/2020/12/15/vue-quick-shot-downloading-data-as-a-file
        const filename = 'ChoiceTemplate.csv'

        const element = document.createElement('a')
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text))
        element.setAttribute('download', filename)

        element.style.display = 'none'
        document.body.appendChild(element)

        element.click()
        document.body.removeChild(element)
      },

      // Draft form methods
      choiceFileSelected () {
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const main = this.$store.state.main
        // console.log(main.orgid)
        if (this.xlsform !== undefined) {
          if (Object.keys(this.xlsform).length === 0) {
            // const fpath = `${this.orgid}/${this.prjid}/${this.formid}`
            // console.log(fpath)

            this.xlsfuploading = true
            const formData = new FormData()
            formData.append('orgid', JSON.stringify(main.orgid))
            formData.append('prjid', JSON.stringify(main.ap.prjid))
            formData.append('formid', JSON.stringify(main.afid))
            formData.append('xlsform', this.xlsform)
            // console.log(formData)
            this.$httpOcpu
              // .post(`/${ocpuPack}/R/ocxlsform/json`, formData).then((res) => {
              .post(`/${ocpuPack}/R/ocxlsform/json`, formData).then((res) => {
                this.formLang = res.data
                this.defLang = res.data[0]
                this.xlsfuploading = false
                // console.log(res)
              })
          }
        }
      },

      // Save/delete/load questions' value part
      async odkFormSave (prop) {
        // console.log('odkFormSave')
        // console.log(prop)
        // clone settings and reassign it's state according to formtype
        const settingsclone = JSON.parse(JSON.stringify(this.$store.state.main.af.odksettings))
        // settingsclone.state = formtype
        settingsclone.state = 'draft'
        // console.log(JSON.parse(JSON.stringify(this.myValues)))

        // Keys to be deleted from questions
        const keys = ['$droppable', 'active', 'draggable', 'fresh', 'icon', 'odk_label', 'settings', 'odk_name', 'text', 'title', 'valid']

        const selectedQuestions = prop.q === 'full' ? this.treeData : [this.groupForTmpl]
        const selectedValues = prop.q === 'full' ? this.myValues : this.groupValues(this.groupForTmpl)
        // console.log(selectedValues)
        // console.log(this.customSection)
        // console.log(this.dSection)
        const versionKey = Math.round((new Date().getTime() / 10))
        const mysection = ['tprivate', 'tpublic', 'tglobal'].includes(prop.ft)
          ? this.customSection
            ? { name: 'custom', label: this.dcSection }
            : this.dSection
          : {}
        // console.log(mysection)
        const fd = {
          settings: settingsclone,
          values: selectedValues,
          questions: selectedQuestions,
          questionsPure: this.cleanObj(selectedQuestions, keys),
          languages: this.languages,
          title: this.dTitle,
          section: mysection,
          private: (prop.ft !== 'main' && prop.ft !== 'draft') && this.dPrivate,
          lang: this.defaultLang[0].code,
          author: this.$store.state.bend.organization.title,
          desc: this.dDesc,
          vkey: versionKey,
        }
        // Changing '</' which give error to '< /' from array value
        // https://stackoverflow.com/a/17537596
        const censor = (key, value) => {
          if (typeof value === 'string') {
            return (value.replace('</', '< /'))
          } else {
            return value
          }
        }
        const formdetails = JSON.stringify(fd, censor)
        // console.log(JSON.parse(formdetails))
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/savefdetails/json`, {
              formdetails: formdetails,
              orgid: this.$store.state.main.orgid,
              prjid: this.$store.state.main.ap.prjid,
              formid: this.$store.state.main.afid,
              formtype: prop.ft,
            })
            .then(response => {
              // console.log(response)
              if (response.status === 201) {
                if (prop.ft === 'main') {
                  this.odkFormUpdated = false
                  this.odkFormSaved = true
                }
                this.dSection = {}
                this.dTitle = ''
                this.dDesc = ''
                resolve(response)
              }
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
        })
      },
      async saveDraft () {
        const type = 'draft'
        await this.odkFormSave({ ft: type, q: 'full' })
        this.getFormList(type)
      },
      async saveTemplate () {
        // console.log('saveTemplate')
        // console.log(this.dPrivate)
        const type = this.dPrivate ? 'tprivate' : 'tpublic'
        this.$refs.tree.walkTreeData((node) => {
          if (node.active === true) {
            this.groupForTmpl = node
          }
        })
        await this.odkFormSave({ ft: type, q: 'selected' })
        this.dialogSaveTemplate = false
        this.snackBar = {
          type: 'success',
          mode: 'multi-line',
          timeout: 1500,
          title: `${this.$t('common.success')}`,
          text: 'Template successfully saved.',
          visible: true,
        }
        // this.getFormList(type)
      },
      async deleteDraft (prop) {
        // console.log(prop)
        await this.deleteForm({ form: prop.item, type: prop.type })
        this.getFormList(prop.type)
      },
      async loadDraft (item) {
        this.dialogConfirm = {
          active: true,
          type: 'loadDraft',
          title: 'Heads up',
          text: 'Your active form will be replaced',
          data: item,
        }
      },
      async loadTheTemplate (prop) {
        // console.log('loadTemplate')

        const r = await this.getDetails({ form: prop.form, type: prop.type })
        const updatedSet = await this.newInternalKey({ q: r.questions[0], v: r.values })
        // console.log(updatedSet)
        // Find active node
        let found = { node: {}, index: null, parent: null, path: [] }
        this.$refs.tree.walkTreeData((node, index, parent, path) => {
          if (node.active === true) {
            found = { node, index, parent, path }
            node.active = false
          }
        })

        const apath = []
        if (found.path.length > 1) {
          const position = found.path[found.path.length - 1] // since it starts with 0 we need -1
          // Convert a JavaScript string in dot notation into an object reference
          // revized code from https://stackoverflow.com/a/69459511 , here I'm using array
          const aget = (t, path) => path.reduce((r, k) => r?.[k], t)
          for (let k = 0; k + 1 < found.path.length; k++) {
            apath.push(found.path[k], 'children')
          }
          aget(this.treeData, apath).splice(position + 1, 0, updatedSet.q)
        } else {
          // if selected question is in the root
          this.treeData.splice(found.path[0] + 1, 0, updatedSet.q)
        }
        // console.log(updatedSet.v)
        this.myValues = this.myValues.concat(updatedSet.v)
        // console.log(this.myValues)
        // console.log(this.treeData)
        // this.odkFormUpdated = true
      },
      async deleteForm (prop) {
        // console.log(item)
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        // console.log(JSON.stringify(prop.form))
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/deletefdetails/json`, {
              formsettings: JSON.stringify(prop.form),
              orgid: this.$store.state.main.orgid,
              prjid: this.$store.state.main.ap.prjid,
              formid: this.$store.state.main.afid,
              formtype: prop.type,
            })
            .then(response => {
              // console.log(response)
              if (response.status === 201) {
                resolve(response)
              }
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
        })
      },

      // Build ODK Form
      buldForm () {
        this.building = true
        // console.log('building')
        this.$store
          .dispatch({
            type: 'ocpu/buldForm',
            orgid: this.$store.state.main.orgid,
            prjid: this.$store.state.main.ap.prjid,
            formid: this.$store.state.main.afid,
          })
          .then(response => {
            // console.log(response)
            this.building = false
            if (response.data[0] === 'success') {
              this.odkFormSaved = false
              this.odkFormBuild = true
              this.snackBar = {
                type: 'success',
                mode: 'multi-line',
                timeout: 1500,
                title: `${this.$t('common.success')}`,
                text: 'The form is successfully built.',
                visible: true,
              }
            } else {
              this.snackBar = {
                type: 'error',
                mode: 'multi-line',
                timeout: 3000,
                title: `${this.$t('common.error')}`,
                text: 'The form was not built.',
                visible: true,
              }
            }
          })
          .catch(error => {
            this.building = false
            // console.log(error)
            this.snackBar = {
              type: 'error',
              mode: 'multi-line',
              timeout: 9000,
              // title: error.response.statusText,
              text: `The form was not built. ${error.response.data}`,
              visible: true,
            }
          })
      },

      // Deploy ODK form
      async deployForm (payload) {
        const prop = {
          ocpu: {
            fsettings: JSON.stringify(this.$store.state.main.af.odksettings),
            orgid: this.$store.state.main.orgid,
            prjid: this.$store.state.main.ap.prjid,
            formid: this.$store.state.main.afid,
            deflang: this.defaultLang[0].code,
          },
          ix: this.$store.state.main.ix,
          caller: 'fd',
        }
        // console.log(prop)
        this.uploadXLSForm(prop)
      },

      menuSelected (prop) {
        // console.log(prop)
        switch (prop) {
          case 'dialogLangs':
            this.dialogLangs = true
            break
          case 'downloadForm':
            this.downloadForm()
            break
          case 'drafts':
            this.dialogDrafts = true
            break
          case 'loadTemplate':
            this.getFormList('tprivate')
            this.dialogLoadTemplate = true
            break
          case 'saveTemplate':
            this.dialogSaveTemplate = true
            break
          default:
            alert('No menu')
        }
      },

      async dialogConfirmed () {
        // Using one dialog for different cases
        switch (this.dialogConfirm.type) {
          case 'loadDraft':
            await this.getDetails({ form: this.dialogConfirm.data, type: 'draft' })
            this.odkFormUpdated = true
            this.dialogConfirm.active = false
            break
          case 'removeTranslation':
            this.deleteTranslation()
            break
          case 'removeAllQuest':
            this.removeAllQuestions(this.dialogConfirm.data)
            break
          default:
            alert('No dialog')
        }
      },

      dialogNotConfirmed () {
        //
        switch (this.dialogConfirm.type) {
          case '_loadDraft':
            break
          default:
            this.dialogConfirm.active = false
        }
      },

      removeAllQuestionsRequest (prop) {
        // console.log(prop)
        let dtext
        if (prop.type === 'all') {
          // Going to delete all questions
          dtext = 'All questions will be deleted immediately. You can\'t undo this action.'
        } else if (prop.type === 'node') {
          // Going to delete group's questions
          const q = this.$refs.tree.getNodeByPath(prop.npath)
          // console.log(q)
          // Get all internalKey values from nested questions of group https://stackoverflow.com/a/52363221
          const keys = []
          JSON.stringify(q, (key, value) => {
            if (key === 'internalKey') keys.push(value)
            return value
          })
          prop.key = keys

          dtext = 'This group and it\'s questions will be deleted immediately. You can\'t undo this action.'
        }
        this.dialogConfirm = {
          active: true,
          type: 'removeAllQuest',
          title: 'Heads up',
          text: dtext,
          data: prop,
        }
      },
      removeAllQuestions (prop) {
        // console.log(this.removeAllValue)
        this.odkFormUpdated = true
        this.dialogConfirm.active = false
        if (prop.type === 'all') {
          // this.modelValues = []
          this.myValues = []
          this.treeData = []
          this.currentVal = ''
          this.questionUnselected()
        } else {
          this.$refs.tree.removeNodeByPath(prop.npath)
          this.removeMValueByKeys(prop.key) // using form-design mixin
        }
        // this.removeAllValue = { type: '', key: '', path: [] }
      },
      removeByPath (prop) {
        // console.log(prop)
        this.odkFormUpdated = true
        this.$refs.tree.removeNodeByPath(prop.npath)
        this.removeMValueByKeys(prop.key) // using form-design mixin
        // this.removeAllValue = { type: '', key: '', path: [] }
        this.settingsMini = true
      },
      async duplicateThisNode (prop) {
        // console.log(prop)
        // console.log(prop.key[0])

        const filtered = (prop.node.type === 'group')
          ? this.groupValues(prop.node)
          : this.myValues.filter(v => v.internalKey === prop.key[0])
        // console.log(filtered)

        const updatedSet = await this.newInternalKey({
          q: JSON.parse(JSON.stringify(prop.node)),
          v: JSON.parse(JSON.stringify(filtered)),
        })
        // console.log(updatedSet)

        const apath = []
        if (prop.npath.length > 1) {
          const position = prop.npath[prop.npath.length - 1] // since it starts with 0 we need -1
          // Convert a JavaScript string in dot notation into an object reference
          // revized code from https://stackoverflow.com/a/69459511 , here I'm using array
          const aget = (t, path) => path.reduce((r, k) => r?.[k], t)
          for (let k = 0; k + 1 < prop.npath.length; k++) {
            apath.push(prop.npath[k], 'children')
          }
          aget(this.treeData, apath).splice(position + 1, 0, updatedSet.q)
        } else {
          // if selected question is in the root
          this.treeData.splice(prop.npath[0] + 1, 0, updatedSet.q)
        }
        // console.log(updatedSet.v)
        this.myValues = this.myValues.concat(updatedSet.v)
        this.odkFormUpdated = true
      },

      // Return list of all saved by saveFormDetails() json files from OCPU
      getFormList (formtype) {
        // console.log(`${formtype} getFormList`)
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/getformlist/json`, {
              orgid: this.$store.state.main.orgid,
              prjid: this.$store.state.main.ap.prjid,
              formid: this.$store.state.main.afid,
              formtype: formtype,
            })
            .then(response => {
              // console.log(response)
              if (response.status === 201) {
                this.formList[formtype] = response.data
                // console.log(this.formList)
                resolve(response)
              }
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
        })
      },

      // Alert when closing updated but not saved odk form
      confirmLeave () {
        return window.confirm(this.$t('form-design.leaving-page'))
      },
      // only if user is not a viewer
      confirmStayInDirtyForm () {
        if (this.myRole() !== 'viewer') {
          return this.odkFormUpdated && !this.confirmLeave()
        }
      },
      beforeWindowUnload (e) {
        if (this.confirmStayInDirtyForm()) {
          // Cancel the event
          e.preventDefault()
          // Chrome requires returnValue to be set
          e.returnValue = ''
        }
      },

      // Download XLSForm (excel version of the ODK form)
      downloadForm () {
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        // console.log(filename)
        this.$httpOcpu.post(`/${ocpuPack}/R/getxlsform/json`, {
          orgid: this.$store.state.main.orgid,
          prjid: this.$store.state.main.ap.prjid,
          formid: this.$store.state.main.afid,
        })
          .then(response => {
            // console.log(response)
            const link = `${response.headers.location}/files/${response.data[0]}`
            const fileName = response.data[0]
            axios({
              url: link,
              method: 'GET',
              responseType: 'blob',
            }).then(response => {
              const fileURL = window.URL.createObjectURL(new Blob([response.data]))
              const fileLink = document.createElement('a')
              fileLink.href = fileURL
              fileLink.setAttribute('download', fileName)
              document.body.appendChild(fileLink)
              fileLink.click()
              window.URL.revokeObjectURL(fileURL)
            })
          })
      },

    },
  }
</script>

<style>
  .__object-form-item > legend {
    display: block;
    padding: 4px;
  }
</style>
