module.exports = {
  title: 'Metadata',
  icon: 'mdi-tag-text',
  class: 'item',
  // ==== SCHEMA =====
  schema: {
    type: 'object',
    properties: {
      internalKey: {
        type: 'number',
        ui: {
          hidden: true,
        },
      },
      type: {
        type: 'string',
        ui: {
          hidden: true,
        },
      },
      odk_name: {
        type: 'string',
        ui: {
          noLabelSpace: true,
          widgetConfig: {
            size: 'mini',
            clearable: true,
            updateOn: 'blur',
            compound: {
              prependLabel: 'Name',
            },
          },
        },
        rules: {
          required: {
            value: true,
            errMsg: 'Required!',
          },
          pattern: {
            value: '^[a-zA-Z0-9_]+$',
            errMsg: 'Only numbers, letters and underscore',
          },
        },
      },
      sectionLabel: {
        type: 'object',
        properties: {
          odk_label: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widgetConfig: {
                  size: 'mini',
                  clearable: true,
                  updateOn: 'blur',
                },
              },
              // rules: {
              //   required: true,
              // },
            },
            ui: {
              showLegend: false,
              noLabelSpace: true,
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
                disableCollapse: true,
              },
            },
          },
        },
        ui: {
          legend: 'Label',
          noLabelSpace: true,
        },
      },
      sectionAppearance: {
        type: 'object',
        properties: {
          meta_type: {
            type: 'string',
            ui: {
              label: 'Select Metadata',
              widget: 'select',
              widgetConfig: {
                size: 'mini',
                enumSource: [
                  {
                    value: 'deviceid',
                    label: 'Device ID',
                  },
                  {
                    value: 'start',
                    label: 'Device Start',
                  },
                  {
                    value: 'end',
                    label: 'Device End',
                  },
                  {
                    value: 'today',
                    label: 'Today',
                  },
                  {
                    value: 'simserial',
                    label: 'SIM Serial',
                  },
                  {
                    value: 'audit',
                    label: 'Audit',
                  },
                ],
                updateOn: 'blur',
              },
            },
            rules: {
              required: true,
            },
          },
        },
        ui: {
          legend: 'Widget Type',
          noLabelSpace: true,
        },
      },
      sectionAdvanced: {
        type: 'object',
        properties: {
          odk_relevant: {
            type: 'string',
            ui: {
              columns: 9,
              label: 'Relevants',
              hidden: 'dx: {{$root.sectionAppearance.meta_type}} === "audit"',
              // description: '',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
          btn_relevant: {
            type: 'button',
            ui: {
              columns: 3,
              widget: 'add-relevant',
              hidden: 'dx: {{$root.sectionAppearance.meta_type}} === "audit"',
              showLabel: false,
            },
          },
          odk_parameters: {
            type: 'string',
            ui: {
              label: 'Parameters',
              hidden: 'dx: {{$root.sectionAppearance.meta_type}} !== "audit"',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
          odk_note: {
            type: 'string',
            ui: {
              label: 'Internal comment',
              description: 'Internal description (note for yourself :))',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
        },
        ui: {
          legend: 'Advanced',
          noLabelSpace: true,
        },
      },
    },
    globalConfig: {
      constants: { // Global constant configuration, can be accessed by {{$const.userName}}
        lang: [],
        choice: [
          {
            lang: [],
          },
        ],
      },
    },
    value: {
      internalKey: '',
      type: '',
      odk_name: '',
      sectionLabel: {
        odk_label: [''],
      },
      sectionAppearance: {
        meta_type: '',
      },
      sectionAdvanced: {
        odk_relevant: '',
        btn_relevant: '',
        odk_note: '',
      },
    },
  },
}
