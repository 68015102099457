<template>
  <div>
    <el-button
      type="info"
      disabled
      icon="el-icon-tickets"
      size="mini"
      circle
      @click="submit('list')"
    />
    <el-button
      type="primary"
      icon="el-icon-edit"
      size="mini"
      circle
      @click="submit('edit')"
    />
  </div>
</template>

<script>
  import ncformCommon from '@ncform/ncform-common'

  export default {
    mixins: [ncformCommon.mixins.vue.controlMixin],

    data () {
      return {
        defaultConfig: {
          // your config's default value ( Note: use mergeConfig to get config value )
          name: 'daniel',
        },
        dialogVisible: false,
      }
    },

    methods: {
      // you can handle the modelVal before $emit it (before this.$emit('input'))
      _processModelVal (modelVal) {
        return modelVal
      },
      submit () {
        // console.log('going')
        this.$store.commit('odkform/dialogAddCode', { caller: 'relevant', value: true })
      },
      handleClose (done) {
        this.$confirm('Are you sure to close this dialog?')
          .then(_ => {
            done()
          })
          .catch(_ => {})
      },

    },
  }
</script>

<style scoped>
.my-custom-comp {
  text-align: left;
  line-height: 3;
}
</style>
