/**
 * Functions used while form design
 */

import Vue from 'vue'

export default {
  data: () => ({
    code: { named: '', keyed: '' },
    questionList: [],
    selectedVar: {},
    ffunction: {},
    selectedChoice: {},
    selectedOperand: '',
    operands: [
      { label: 'Empty', code: '' },
      { label: 'Less than', code: '<' },
      { label: 'Greater than', code: '>' },
      { label: 'Less than or equal', code: '<=' },
      { label: 'Greater than or equal', code: '>=' },
      { label: 'Equal', code: '=' },
      { label: 'Not equal', code: '!=' },
    ],
    editBtn: [
      { calc: 0, label: 'if', color: 'indigo', clicktxt: 'if(expression, IF_TRUE, IF_FALSE)' },
      { calc: 1, label: '(', color: 'indigo', clicktxt: ' (' },
      { calc: 1, label: ')', color: 'indigo', clicktxt: ') ' },
      { calc: 1, label: 'or', color: 'indigo', clicktxt: ' or ' },
      { calc: 1, label: 'and', color: 'indigo', clicktxt: ' and ' },
      { calc: 1, label: 'current', color: 'indigo', clicktxt: ' . ' },
    ],
    varValue: null,
  }),

  watch: {
    '$store.state.odkform.dialogAddCode.value': function () {
      // console.log('watching, dialogAddCode: ' + this.$store.state.odkform.dialogAddCode.value)
      const caller = this.$store.state.odkform.dialogAddCode.caller
      const schm = JSON.parse(JSON.stringify(this.schemavalue))
      switch (caller) {
        case 'rcount':
          this.code.named = schm.sectionAppearance.odk_repeat_count
          break
        case 'relevant':
          this.code.named = schm.sectionAdvanced.odk_relevant
          break
        case 'constraint':
          this.code.named = schm.sectionAdvanced.odk_constraint
          break
        case 'calculate':
          this.code.named = schm.sectionAdvanced.odk_calculation
          break
        default:
          this.code.named = ''
      }
    },
  },

  computed: {
   choiceVarList () {
      const tmp = JSON.parse(JSON.stringify(this.selectedVar))
      // console.log(tmp)
      // const modelnew = JSON.parse(JSON.stringify(this.myValues))
      const model = this.myValues.filter(
        m => m.internalKey === tmp.internalKey,
      )
      // If first generated and choice not added it doesn't have odk_clabel only [__dataSchema], fixing it
      // console.log('odk_name' in model[0].sectionChoices.choices[0])
      if ('odk_name' in model[0].sectionChoices.choices[0]) {
        return (model[0].sectionChoices.choices)
      } else {
        return [{ odk_clabel: '', odk_name: '' }]
      }
    },
    questVarList () {
      // const modelsClonen = JSON.parse(JSON.stringify(this.myValues))
      const modelsClone = JSON.parse(JSON.stringify(this.myValues))
      // console.log(modelsClonen)
      // console.log(modelsClone)
      const qlist = modelsClone.map(q => ({
        internalKey: q.internalKey,
        name: q.odk_name,
        type: q.type,
          label: (
            q.sectionLabel.odk_label[0] !== '' &&
            'odk_label' in q.sectionLabel &&
            typeof q.sectionLabel.odk_label[0] === 'string'
          )
            ? q.sectionLabel.odk_label[0]
            : `${q.odk_name} (data name)`,
        // label: `${q.odk_name} (data name)`,
      }))
      // console.log(qlist)
      this.questionList = qlist
      return (qlist)
    },
  },

  methods: {

    questVarSelected (dataSet) {
      // console.log(dataSet)
      // console.log(this.selectedVar)
      this.selectedChoice = {}
      this.selectedOperand = ''
      this.varValue = null
    },

    choiceSelected (choice) {
      // console.log(choice)
      this.selectedChoice = choice
      // find table object by filtering selected value
      // const myData = this.$store.state.ocpu.tableListRules.filter(function (item) {
      //   return item.table.includes(dataSet.table)
      // })

      // // Some data has undefined type : change them into 'text'
      // dataSet.column.forEach(function (part) {
      //   if (part.type === undefined) {
      //     part.type = 'text'
      //   }
      // })
      // this.rules = dataSet.column
      // // console.log(this.rules)
    },

    execInsertText (text) {
      // this.sortModel()
      // https://codepen.io/1da2/pen/RwWbROE
      if (!text.length) return

      const textarea = this.$refs.textarea.$refs.input
      let sentence = textarea.value

      // Delete (if any) selected text https://stackoverflow.com/a/36859386
      sentence = sentence.slice(0, textarea.selectionStart) + sentence.slice(textarea.selectionEnd)

      const len = sentence.length
      // console.log(textarea)
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.code.named = before + text + after

      this.$nextTick().then(() => {
        textarea.selectionStart = pos + text.length
      })
    },

    addCode () {
      // console.log(this.selectedVar)
      // console.log(this.selectedOperand)
      // change null if code textarea was cleared with textarea clearable prop
      // if (this.code.named === null) {
      //   this.code.named = ''
      //   this.code.keyed = ''
      // }
      let vvalue = ''
      if (this.selectedVar.type === 'chooseone' || this.selectedVar.type === 'selectmultiple') {
        vvalue = this.selectedChoice.odk_name
      } else {
        vvalue = this.varValue
      }
      // console.log(vvalue)
      let text = ''
      if (this.selectedVar.type === 'selectmultiple') {
        if (vvalue === undefined) {
          text = ''
        } else {
          text = ' selected(${' + this.selectedVar.name + `}, '${vvalue}') `
        }
      } else {
        if (this.selectedOperand === '') {
          text = ' ${' + this.selectedVar.name + '}'
        } else {
          // console.log(vvalue)
          // console.log(typeof vvalue)
          if (isNaN(parseInt(vvalue))) {
            text = ' ${' + this.selectedVar.name + `} ${this.selectedOperand.code} '${vvalue}' `
          } else {
            text = ' ${' + this.selectedVar.name + `} ${this.selectedOperand.code} ${vvalue} `
          }
        }
      }
      if (Object.keys(this.selectedVar).length === 0) text = ''
      this.execInsertText(text)
    },

    async moveCode () {
      const schm = JSON.parse(JSON.stringify(this.schemavalue))
      // console.log(schm)
      const caller = this.$store.state.odkform.dialogAddCode.caller
      switch (caller) {
        case 'rcount':
          schm.sectionAppearance.odk_repeat_count = this.code.named.trim()
          break
        case 'relevant':
          schm.sectionAdvanced.odk_relevant = this.code.named.trim()
          break
        case 'constraint':
          schm.sectionAdvanced.odk_constraint = this.code.named.trim()
          break
        case 'calculate':
          schm.sectionAdvanced.odk_calculation = this.code.named.trim()
          break
        default:
          alert('No field for code')
      }
      await Vue.nextTick()
      this.schemavalue = schm
      // After assign value onchange will be triggered make firstSelect = false to keep changes
      this.firstSelect = false
      // TODO Check this
      this.$store.commit('odkform/dialogAddCode', { caller: '', value: false })
    },
  },
}
