<script>
  import Vue from 'vue'
  import 'he-tree-vue/dist/he-tree-vue.css'
  import { Tree, Fold, Check, Draggable } from 'he-tree-vue'

  import VueI18n from 'vue-i18n'

  Vue.use(VueI18n)

  export default {
    extends: Tree,
    mixins: [Fold, Check, Draggable],
    props: {
      // Prevent drag by default.
      draggable: { type: [Boolean, Function], default: false },
      droppable: { type: [Boolean, Function], default: false },
      triggerClass: { type: String, default: 'drag-trigger' },
      title: { type: String, default: '' },
    },
    emits: ['settings'],
    data () {
      return {
        treeClass: 'my-tree-view',
        searched: false,
      }
    },
    computed: {
      total () {
        let i = 0
        this.walkTreeData((node) => {
          i++
        })
        return i
      },
    },
    methods: {
      // Header
      blockHeader () {
        return <div class="header">
          <i>{`${this.title} (question nodes: ${this.total})`}</i>
          <input class="search" id="searchInput" onKeydown={(e) => e.key === 'Enter' && this.search(e)} placeholder="Search" />
          { this.searched
            ? <button class={'mls mrs btn mdi red-color mdi-cancel' } onClick={this.showHidden}></button>
            : <button class={'mls mrs btn mdi green-color mdi-magnify' } onClick={this.search}></button>
          }

        </div>
      },

      // Main part, questions
      overrideSlotDefault ({ node, index, path, tree }, original) {
        // Preparing choice items
        let item = ''
        if (node.settings.choiceSource === 'external') {
          item = <span>{node.settings.choiceFile}</span>
        } else {
          item = 'settings' in node && node.settings.choiceList.map((item, index) => {
            // change <span> to div to see in rows
            return <span>({item.odk_name}) {item.odk_clabel[0]}; &ensp;</span>
          })
        }
        return <template class={node.active ? 'node-content active' : 'node-content'} onclick={() => this.nodeActive(tree, node)}>

          <button class="blue-color mdi mdi-dots-grid btn drag-trigger"></button>

          {node.$droppable
          ? node.$folded
          ? <button class={'mls mrs mbs btn mdi ' + node.settings.icon } onClick={() => tree.toggleFold(node, path)}></button>
          : <button class={'mls mrs btn mdi mdi-ungroup' } onClick={() => tree.toggleFold(node, path)}></button>
          : <i class={'mls mrs btn mdi ' + node.settings.icon }></i>}

          {<div>
            { /* Question title / variable (var) */ }
            {(node.settings.odk_label[0] !== '' && node.settings.odk_label.length > 0)
              ? node.settings.odk_label[0]
              : <i class={'choice' }>{node.odk_name + ' (var)'} </i>
            }
            <div>
             { /* Choice list block */ }
              {((node.type === 'chooseone' || node.type === 'selectmultiple') && 'settings' in node)
                ? <i class={'mls mrs choice' } style={'display: ' + node.settings.displayChoice }>
                    <b>Choices:</b> {item}
                  </i>
                : ''}
            </div>
          </div>}
          <div class="right">
            { /* Choice list hide/show button */ }
            {((node.type === 'chooseone' || node.type === 'selectmultiple') && 'settings' in node)
              ? node.settings.displayChoice === 'none'
                ? <button class={'blue-color btn mdi mdi-chevron-down' } onClick={() => this.choiceList(node, path)}></button>
                : <button class={'blue-color btn mdi mdi-chevron-up' } onClick={() => this.choiceList(node, path)}></button>
              : ''
            }
            { /* Validation indicator icon */ }
            {node.valid
            ? <i class={'mls mrs mdi mdi-checkbox-marked-outline green-color' }></i>
            : <i class={'mls mrs mdi mdi-alert-box-outline orange-color' }></i>}

            <div class="dropdown">
              <button class="blue-color mdi mdi-dots-vertical btn dropbtn"></button>
              <div class="dropdown-content">
                <button class="mdi mdi-content-duplicate green-color" onClick={() => this.duplicateNode(node, path)}></button>
                {node.$droppable
                ? <button class="mls mdi mdi-delete red-color" onClick={() => this.removeTreeData({ type: 'node', npath: path, key: [] })}></button>
                : <button class="mls mdi mdi-delete-outline red-color" onClick={() => this.removeNode(path)}></button>}
              </div>
            </div>
          </div>
        </template>
      },

      // Footer
      blockFooter () {
        return <div>
          {this.total
            ? <div class="footer">
            <span class="node-content mts">
              <div class="right">
                <button class="mls mdi mdi-delete-alert red-color" onClick={() => this.removeTreeData(
                  { type: 'all', npath: [], key: [] },
                )}></button>
              </div>
            </span>
          </div>
          : 'Add a question from left panel to start'}
        </div>
      },

      showHidden () {
        this.walkTreeData((node) => {
          this.$set(node, '$hidden', false)
        })
        // clear searched text from input
        const stext = document.getElementById('searchInput')
        stext.value = ''
        this.searched = false
      },
      hideNode (node) {
        this.$set(node, '$hidden', true)
      },
      nodeActive (tree, node) {
        tree.walkTreeData((node) => {
          node.active = false
        })
        this.$set(node, 'active', true)
        this.$emit('settings', node)
      },
      search () {
        const sInput = document.getElementById('searchInput')
        const value = sInput.value || ''
        this.walkTreeData((node) => {
          // match brings the the hole word that has value
          const match = node.settings.odk_label.find(e => {
            if (e.toLowerCase().includes(value.toLowerCase())) {
              return true
            }
          })
          const name = node.odk_name.includes(value)
          const label = match !== undefined
          const foo = name || label
          this.$set(node, '$hidden', (!foo))
        })
        this.searched = true
      },
      choiceList (node) {
        const state = node.settings.displayChoice === 'none' ? 'block' : 'none'
        // console.log(state)
        this.$set(node.settings, 'displayChoice', state)
      },
      nodeSettings (node) {
        this.$emit('settings', node)
      },
      removeTreeData (prop) {
        // console.log(prop)
        this.$emit('deleteAll', prop)
      },
      removeNode (path) {
        // console.log(path)
        const q = this.getNodeByPath(path)
        // console.log(q)
        // this.removeNodeByPath(node)
        this.$emit('deleteByPath', { type: 'node', npath: path, key: [q.internalKey] })
      },
      duplicateNode (node, path) {
        // console.log(path)
        const q = this.getNodeByPath(path)
        this.$emit('duplicateNode', { node: node, npath: path, key: [q.internalKey] })
      },
    },
  }
</script>

<style>
  @import 'https://cdn.jsdelivr.net/npm/@mdi/font@6.6.96/css/materialdesignicons.min.css';

  .my-tree-view .mls{
    margin-left: 5px;
  }
  .my-tree-view .mrs{
    margin-right: 5px;
  }
  .my-tree-view .mts{
    margin-top: 5px;
  }
  .my-tree-view .pts{
    padding-top: 5px;
  }
  .my-tree-view .mbs{
    margin-bottom: 10px;
  }
  .my-tree-view .tree-node{
    /* padding: 4px; */
    margin-right: 20px;
    padding-right: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .my-tree-view .node-content{
    display: flex;
  }
  .my-tree-view .node-content .choice{
    font-size: small;
  }
  .my-tree-view .node-content .fold-btn{
    display: flex;
    justify-content: center;
    width: 22px;
    border-radius: 100%;
    border: none;
    background: #fcf1a8;
  }
  .my-tree-view .tree-node-back:hover{
    background: #f5f5f5;
    /* font-weight: bold; */
  }
  .my-tree-view .header{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  .my-tree-view .footer{
    border-top: 1px solid #ccc;
    margin-top: 10px;
  }

  .active {
    /* background: #d1deff; */
    /* border-radius: 4px; */
    font-weight: bolder;
    color: red;
  }
  .search {
    width: 130px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-repeat: no-repeat;
    padding: 5px;
    transition: width 0.4s;
    margin-left: auto;
    margin-right: 0;
  }
  .search:focus {
    width: 50%;
  }
  .right {
    /* position: absolute; */
    margin-left: auto;
    margin-right: 0;
    right: 0px;
    padding-right: 10px;
  }
  .btn {
    font-size:large;
  }
   /* Colors */
  .red-color {
    color: red;
  }
  .green-color {
    color: green;
  }
  .blue-color {
    color: blueviolet;
  }
  .orange-color {
    color: orange;
  }
   /* Dropdown Button */
  .dropbtn {
    /* background-color: #04AA6D; */
    /* color: white; */
    /* padding: 16px; */
    /* font-size: 16px; */
    border: none;
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    border: 1px solid rgb(222, 222, 222);
    background-color: #ffffff;
    border-radius: 4px;
    padding: 8px;
    min-width: 55px;
    /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
    box-shadow: 0px 5px 15px grey;
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #ddd;}

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}

  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {color: red;}
</style>
