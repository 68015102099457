module.exports = {
  title: 'Location',
  icon: 'mdi-map-marker',
  class: 'item',
  // ==== SCHEMA =====
  schema: {
    type: 'object',
    properties: {
      internalKey: {
        type: 'number',
        ui: {
          hidden: true,
        },
      },
      type: {
        type: 'string',
        ui: {
          hidden: true,
        },
      },
      odk_name: {
        type: 'string',
        ui: {
          noLabelSpace: true,
          widgetConfig: {
            size: 'mini',
            clearable: true,
            updateOn: 'blur',
            compound: {
              prependLabel: 'Name',
            },
          },
        },
        rules: {
          required: {
            value: true,
            errMsg: 'Required!',
          },
          pattern: {
            value: '^[a-zA-Z0-9_]+$',
            errMsg: 'Only numbers, letters and underscore',
          },
        },
      },
      sectionLabel: {
        type: 'object',
        properties: {
          odk_label: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widgetConfig: {
                  size: 'mini',
                  clearable: true,
                  updateOn: 'blur',
                },
              },
              rules: {
                required: true,
              },
            },
            ui: {
              showLegend: false,
              noLabelSpace: true,
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
                disableCollapse: true,
              },
            },
          },
        },
        ui: {
          legend: 'Label',
          noLabelSpace: true,
        },
      },
      sectionAppearance: {
        type: 'object',
        properties: {
          location_type: {
            type: 'string',
            value: 'geopoint',
            ui: {
              noLabelSpace: true,
              widget: 'select',
              widgetConfig: {
                size: 'mini',
                placeholder: 'Select geowidget type',
                enumSource: [
                  {
                    value: 'geopoint',
                    label: 'Geopoint',
                  },
                  {
                    value: 'geotrace',
                    label: 'Geotrace',
                  },
                  {
                    value: 'geoshape',
                    label: 'Geoshape',
                  },
                ],
                updateOn: 'blur',
              },
            },
            rules: {
              required: true,
            },
          },
          sectionSettings: {
            type: 'object',
            properties: {
              point_settings: {
                type: 'boolean',
                value: 'none',
                ui: {
                  // label: 'Interactive map',
                  noLabelSpace: true,
                  widget: 'select',
                  widgetConfig: {
                    size: 'mini',
                    enumSource: [
                      {
                        value: 'none',
                        label: 'No map',
                      },
                      {
                        value: 'maps',
                        label: 'Show a map',
                      },
                      {
                        value: 'placement-map',
                        label: 'Allow to select a location',
                      },
                    ],
                  },
                },
              },
              point_accuracy: {
                type: 'boolean',
                ui: {
                  label: 'Point accuracy',
                  hidden: 'dx: {{$root.sectionAppearance.sectionSettings.point_settings}} === "placement-map"',
                  // noLabelSpace: true,
                  widget: 'checkbox',
                  widgetConfig: {
                    enumSource: [
                      {
                        label: 'Require accuracy',
                      },
                    ],
                  },
                },
              },
              capture_accuracy: {
                type: 'number',
                value: 50,
                ui: {
                  label: 'Capture accuracy',
                  hidden: 'dx: !{{$root.sectionAppearance.sectionSettings.point_accuracy}} || {{$root.sectionAppearance.sectionSettings.point_settings}} === "placement-map"',
                  widget: 'input-number',
                  // noLabelSpace: true,
                  widgetConfig: {
                    size: 'mini',
                    step: 10,
                    updateOn: 'blur',
                  },
                },
              },
              warning_accuracy: {
                type: 'number',
                value: 50,
                ui: {
                  label: 'Warning accuracy',
                  hidden: 'dx: !{{$root.sectionAppearance.sectionSettings.point_accuracy}} || {{$root.sectionAppearance.sectionSettings.point_settings}} === "placement-map"',
                  widget: 'input-number',
                  // noLabelSpace: true,
                  widgetConfig: {
                    size: 'mini',
                    step: 10,
                    updateOn: 'blur',
                  },
                },
              },
              mock_accuracy: {
                type: 'boolean',
                ui: {
                  noLabelSpace: true,
                  widget: 'checkbox',
                  label: 'Allow mock accuracy',
                  hidden: 'dx: {{$root.sectionAppearance.sectionSettings.point_settings}} === "placement-map"',
                  // noLabelSpace: true,
                  widgetConfig: {
                    enumSource: [
                      {
                        label: 'Allow mock accuracy',
                      },
                    ],
                    size: 'mini',
                  },
                },
              },
            },
            ui: {
              showLegend: false,
              showLabel: false,
              hidden: 'dx: {{$root.sectionAppearance.location_type}} !== "geopoint"',
              label: '',
              widgetConfig: {
                collapsed: false,
              },
            },
          },
          odk_required: {
            type: 'boolean',
            ui: {
              // label: '.',
              noLabelSpace: true,
              widget: 'checkbox',
              widgetConfig: {
                enumSource: [
                  {
                    label: 'Required',
                  },
                ],
              },
            },
          },
          odk_required_message: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widgetConfig: {
                  size: 'mini',
                  updateOn: 'blur',
                  // compound: {
                  //   prependLabel: 'dx: {{$const.lang[i].code}}',
                  // },
                },
              },
            },
            ui: {
              label: 'Required message',
              showLegend: false,
              hidden: 'dx: {{$root.sectionAppearance.odk_required}} !== true',
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
              },
            },
          },
          odk_note: {
            type: 'string',
            ui: {
              label: 'Internal comment',
              description: 'Internal description (note for yourself :))',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
        },
        ui: {
          legend: 'Appearance',
          noLabelSpace: true,
          widgetConfig: {
            collapsed: false,
          },
        },
      },
      sectionAdvanced: {
        type: 'object',
        properties: {
          odk_relevant: {
            type: 'string',
            ui: {
              columns: 9,
              label: 'Relevants',
              // description: '',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
          btn_relevant: {
            type: 'button',
            ui: {
              columns: 3,
              widget: 'add-relevant',
              showLabel: false,
            },
          },
          odk_constraint: {
            type: 'string',
            ui: {
              columns: 9,
              label: 'Constraint',
              // description: '',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
          btn_constraint: {
            type: 'button',
            ui: {
              columns: 3,
              widget: 'add-constraint',
              showLabel: false,
            },
          },
          odk_constraint_message: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widgetConfig: {
                  size: 'mini',
                  updateOn: 'blur',
                  // compound: {
                  //   prependLabel: 'dx: {{$const.lang[i].code}}',
                  // },
                },
              },
            },
            ui: {
              label: 'Constraint message',
              showLegend: false,
              hidden: 'dx: {{$root.sectionAdvanced.odk_constraint}} === ""',
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
              },
            },
          },
          odk_note: {
            type: 'string',
            ui: {
              label: 'Internal comment',
              description: 'Internal description (note for yourself :))',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
        },
        ui: {
          legend: 'Advanced',
          noLabelSpace: true,
          widgetConfig: {
            collapsed: true,
          },
        },
      },
    },
    globalConfig: {
      constants: { // Global constant configuration, can be accessed by {{$const.userName}}
        lang: [],
        choice: [
          {
            lang: [],
          },
        ],
      },
    },
    value: {
      internalKey: '',
      type: '',
      odk_name: '',
      sectionLabel: {
        odk_label: [''],
      },
      sectionAppearance: {
        location_type: 'geopoint',
        sectionSettings: {
          point_settings: 'none',
          point_accuracy: false,
          capture_accuracy: 50,
          warning_accuracy: 50,
          mock_accuracy: false,
        },
        odk_required: false,
        odk_required_message: '',
      },
      sectionAdvanced: {
        odk_relevant: '',
        btn_relevant: '',
        odk_constraint: '',
        btn_constraint: '',
        odk_constraint_message: [''],
        odk_note: '',
      },
    },
  },
}
