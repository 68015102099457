module.exports = {
  title: 'Media',
  icon: 'mdi-image',
  class: 'item',
  // ==== SCHEMA =====
  schema: {
    type: 'object',
    properties: {
      internalKey: {
        type: 'number',
        ui: {
          hidden: true,
        },
      },
      type: {
        type: 'string',
        ui: {
          hidden: true,
        },
      },
      odk_name: {
        type: 'string',
        ui: {
          noLabelSpace: true,
          widgetConfig: {
            size: 'mini',
            clearable: true,
            updateOn: 'blur',
            compound: {
              prependLabel: 'Name',
            },
          },
        },
        rules: {
          required: {
            value: true,
            errMsg: 'Required!',
          },
          pattern: {
            value: '^[a-zA-Z0-9_]+$',
            errMsg: 'Only numbers, letters and underscore',
          },
        },
      },
      sectionLabel: {
        type: 'object',
        properties: {
          odk_label: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widget: 'textarea',
                widgetConfig: {
                  rows: 1,
                  size: 'mini',
                  clearable: true,
                  updateOn: 'blur',
                },
              },
              rules: {
                required: true,
              },
            },
            ui: {
              showLegend: false,
              noLabelSpace: true,
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
                disableCollapse: true,
              },
            },
          },
        },
        ui: {
          legend: 'Label',
          noLabelSpace: true,
        },
      },
      sectionAppearance: {
        type: 'object',
        properties: {
          media_type: {
            type: 'string',
            ui: {
              label: 'Media Type',
              widget: 'select',
              widgetConfig: {
                size: 'mini',
                enumSource: [
                  {
                    value: 'image',
                    label: 'Image',
                  },
                  {
                    value: 'audio',
                    label: 'Audio',
                  },
                  {
                    value: 'video',
                    label: 'Video',
                  },
                ],
                updateOn: 'blur',
              },
            },
            rules: {
              required: true,
            },
          },
          image_settings: {
            type: 'string',
            value: 'none',
            ui: {
              label: 'Image settings',
              widget: 'select',
              hidden: 'dx: {{$root.sectionAppearance.media_type}} !== "image"',
              widgetConfig: {
                size: 'mini',
                enumSource: [
                  {
                    value: 'none',
                    label: 'Standart',
                  },
                  {
                    value: 'new',
                    label: 'Require a new',
                  },
                  {
                    value: 'new-front',
                    label: 'Selfie',
                  },
                  {
                    value: 'draw',
                    label: 'Draw',
                  },
                  {
                    value: 'annotate',
                    label: 'Annotation',
                  },
                  {
                    value: 'signature',
                    label: 'Signature',
                  },
                ],
              },
            },
          },
          image_quality: {
            type: 'string',
            value: 'none',
            ui: {
              label: 'Scale down image',
              widget: 'select',
              hidden: 'dx: {{$root.sectionAppearance.media_type}} !== "image"',
              widgetConfig: {
                size: 'mini',
                enumSource: [
                  {
                    value: 'none',
                    label: 'Original',
                  },
                  {
                    value: '640',
                    label: '640 px',
                  },
                  {
                    value: '720',
                    label: '720 px',
                  },
                  {
                    value: '1024',
                    label: '1024 px',
                  },
                ],
              },
            },
          },
          audio_quality: {
            type: 'string',
            value: 'voice-only',
            ui: {
              label: 'Audio quality',
              widget: 'select',
              hidden: 'dx: {{$root.sectionAppearance.media_type}} !== "audio"',
              widgetConfig: {
                size: 'mini',
                enumSource: [
                  {
                    value: 'voice-only',
                    label: 'Only voice',
                  },
                  {
                    value: 'low',
                    label: 'Low',
                  },
                  {
                    value: 'normal',
                    label: 'Normal',
                  },
                  // {
                  //   value: 'external',
                  //   label: 'Use external app',
                  // },
                ],
              },
            },
          },
          video_settings: {
            type: 'string',
            value: 'none',
            ui: {
              label: 'Video settings',
              widget: 'radio',
              hidden: 'dx: {{$root.sectionAppearance.media_type}} !== "video"',
              widgetConfig: {
                size: 'mini',
                enumSource: [
                  {
                    value: 'none',
                    label: 'Standart',
                  },
                  {
                    value: 'new-front',
                    label: 'Require a new',
                  },
                  // {
                  //   value: 'external',
                  //   label: 'Use external app',
                  // },
                ],
              },
            },
          },
        },
        ui: {
          legend: 'Widget Type',
          noLabelSpace: true,
        },
      },
      sectionAdvanced: {
        type: 'object',
        properties: {
          odk_relevant: {
            type: 'string',
            ui: {
              columns: 9,
              label: 'Relevants',
              // description: '',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
          btn_relevant: {
            type: 'button',
            ui: {
              columns: 3,
              widget: 'add-relevant',
              showLabel: false,
            },
          },
          odk_note: {
            type: 'string',
            ui: {
              label: 'Internal comment',
              description: 'Internal description (note for yourself :))',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
        },
        ui: {
          legend: 'Advanced',
          noLabelSpace: true,
        },
      },
    },
    globalConfig: {
      constants: { // Global constant configuration, can be accessed by {{$const.userName}}
        lang: [],
        choice: [
          {
            lang: [],
          },
        ],
      },
    },
    value: {
      internalKey: '',
      type: '',
      odk_name: '',
      sectionLabel: {
        odk_label: [''],
      },
      sectionAppearance: {
        media_type: '',
        image_settings: '',
        image_quality: '',
        audio_quality: '',
        video_settings: '',
      },
      sectionAdvanced: {
        odk_relevant: '',
        btn_relevant: '',
        odk_note: '',
      },
    },
  },
}
