module.exports = {
  title: 'Rank',
  icon: 'mdi-hand-pointing-right',
  class: 'item',
  // ==== SCHEMA =====
  schema: {
    type: 'object',
    properties: {
      internalKey: {
        type: 'number',
        ui: {
          hidden: true,
        },
      },
      type: {
        type: 'string',
        ui: {
          hidden: true,
        },
      },
      odk_name: {
        type: 'string',
        ui: {
          noLabelSpace: true,
          widgetConfig: {
            size: 'mini',
            clearable: true,
            updateOn: 'blur',
            compound: {
              prependLabel: 'Name',
            },
          },
        },
        rules: {
          required: {
            value: true,
            errMsg: 'Required!',
          },
          pattern: {
            value: '^[a-zA-Z0-9_]+$',
            errMsg: 'Only numbers, letters and underscore',
          },
        },
      },
      sectionLabel: {
        type: 'object',
        properties: {
          odk_label: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widget: 'textarea',
                widgetConfig: {
                  rows: 1,
                  size: 'mini',
                  clearable: true,
                  updateOn: 'blur',
                },
              },
              rules: {
                required: true,
              },
            },
            ui: {
              showLegend: false,
              noLabelSpace: true,
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
                disableCollapse: true,
              },
            },
          },
        },
        ui: {
          legend: 'Question label',
          noLabelSpace: true,
        },
      },
      sectionHint: {
        type: 'object',
        properties: {
          odk_hint: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widgetConfig: {
                  size: 'mini',
                  updateOn: 'blur',
                },
              },
            },
            ui: {
              showLegend: false,
              noLabelSpace: true,
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
                disableCollapse: true,
              },
            },
          },
        },
        ui: {
          legend: 'Hints',
          noLabelSpace: true,
          widgetConfig: {
            collapsed: true,
          },
        },
      },
      sectionChoices: {
        type: 'object',
        properties: {
          choice_source: {
            type: 'string',
            value: 'upload',
            ui: {
              label: 'Choice Source',
              widget: 'radio',
              widgetConfig: {
                type: 'button',
                size: 'mini',
                enumSource: [
                  {
                    value: 'upload',
                    label: 'Upload',
                  },
                  {
                    value: 'manual',
                    label: 'Manual',
                  },
                ],
                updateOn: 'blur',
              },
            },
            rules: {
              required: true,
            },
          },
          btn_upload: {
            type: 'button',
            ui: {
              showLabel: false,
              columns: 3,
              hidden: 'dx: {{$root.sectionChoices.choice_source}} !== "upload"',
              widget: 'upload-choices',
            },
          },
          choices: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                odk_name: {
                  type: 'string',
                  ui: {
                    noLabelSpace: true,
                    placeholder: 'Name',
                    widgetConfig: {
                      size: 'mini',
                      updateOn: 'blur',
                      compound: {
                        prependLabel: 'Name',
                      },
                    },
                  },
                  rules: {
                    required: true,
                  },
                },
                odk_clabel: {
                  type: 'array',
                  value: [''],
                  items: {
                    type: 'string',
                    ui: {
                      label: 'dx: {{$const.choice[0].lang[i].full}}',
                      placeholder: 'Label',
                      widgetConfig: {
                        size: 'mini',
                        updateOn: 'blur',
                      },
                    },
                    rules: {
                      required: true,
                    },
                  },
                  ui: {
                    showLegend: false,
                    noLabelSpace: true,
                    widgetConfig: {
                      autoIdxToLabel: false,
                      disableAdd: true,
                      disableDel: true,
                      disableReorder: true,
                      disableItemCollapse: true,
                      disableCollapse: true,
                    },
                  },
                },
              },
              ui: {
                label: 'dx: {{$root.sectionChoices.choices[i].odk_name}} === "" ? "Choice " : {{$root.sectionChoices.choices[i].odk_name}}',
              },
            },
            ui: {
              showLegend: false,
              hidden: 'dx: {{$root.sectionChoices.choice_source}} !== "manual"',
              noLabelSpace: true,
              widgetConfig: {
                showOneIfEmpty: true,
                autoIdxToLabel: false,
              },
            },
          },
        },
        ui: {
          legend: 'Choices',
          noLabelSpace: true,
          widgetConfig: {
            collapsed: false,
          },
        },
      },
      sectionAppearance: {
        type: 'object',
        properties: {
          odk_read_only: {
            type: 'boolean',
            ui: {
              noLabelSpace: true,
              widget: 'checkbox',
              widgetConfig: {
                enumSource: [
                  {
                    label: 'Read only',
                  },
                ],
              },
            },
          },
          odk_required: {
            type: 'boolean',
            ui: {
              noLabelSpace: true,
              widget: 'checkbox',
              widgetConfig: {
                enumSource: [
                  {
                    label: 'Required',
                  },
                ],
              },
            },
          },
          odk_required_message: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widgetConfig: {
                  size: 'mini',
                  updateOn: 'blur',
                  // compound: {
                  //   prependLabel: 'dx: {{$const.lang[i].code}}',
                  // },
                },
              },
            },
            ui: {
              label: 'Required message',
              showLegend: false,
              hidden: 'dx: {{$root.sectionAppearance.odk_required}} !== true',
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
              },
            },
          },
        },
        ui: {
          legend: 'Appearance',
          noLabelSpace: true,
          widgetConfig: {
            collapsed: true,
          },
        },
      },
      sectionAdvanced: {
        type: 'object',
        properties: {
          odk_relevant: {
            type: 'string',
            ui: {
              columns: 9,
              label: 'Relevants',
              // description: '',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
          btn_relevant: {
            type: 'button',
            ui: {
              columns: 3,
              widget: 'add-relevant',
              showLabel: false,
            },
          },
          odk_constraint: {
            type: 'string',
            ui: {
              columns: 9,
              label: 'Constraint',
              // description: '',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
          btn_constraint: {
            type: 'button',
            ui: {
              columns: 3,
              widget: 'add-constraint',
              showLabel: false,
            },
          },
          odk_constraint_message: {
            type: 'array',
            value: [''],
            items: {
              type: 'string',
              ui: {
                label: 'dx: {{$const.lang[i].full}}',
                widgetConfig: {
                  size: 'mini',
                  updateOn: 'blur',
                },
              },
            },
            ui: {
              label: 'Constraint message',
              showLegend: false,
              hidden: 'dx: {{$root.sectionAdvanced.odk_constraint}} === ""',
              widgetConfig: {
                autoIdxToLabel: false,
                disableAdd: true,
                disableDel: true,
                disableReorder: true,
                disableItemCollapse: true,
              },
            },
          },
          odk_note: {
            type: 'string',
            ui: {
              label: 'Internal comment',
              description: 'Internal description (note for yourself :))',
              widgetConfig: {
                size: 'mini',
                updateOn: 'blur',
              },
            },
          },
        },
        ui: {
          legend: 'Advanced',
          noLabelSpace: true,
          widgetConfig: {
            collapsed: true,
          },
        },
      },
    },
    globalConfig: {
      constants: { // Global constant configuration, can be accessed by {{$const.userName}}
        lang: [],
        choice: [
          {
            lang: [],
          },
        ],
      },
    },
    value: {
      internalKey: '',
      type: '',
      odk_name: '',
      sectionLabel: {
        odk_label: [''],
      },
      sectionHint: {
        odk_hint: [''],
      },
      sectionChoices: {
        choice_source: 'upload',
        choices: [
          {
            odk_name: '',
            odk_clabel: [''],
          },
        ],
      },
      sectionAppearance: {
        odk_read_only: false,
        odk_required: false,
        odk_required_message: [''],
      },
      sectionAdvanced: {
        odk_relevant: '',
        odk_constraint: '',
        odk_constraint_message: [''],
        odk_note: '',
      },
    },
  },
}
